import { FC, ReactNode, useEffect } from 'react'
import { Button } from '../Button'

interface Props {
  title?: string
  text?: string
  icon?: string
  children?: ReactNode
  isOpen: boolean
  onClickClose?: () => void
}
const Modal: FC<Props> = ({ title, text, icon, children, isOpen, onClickClose }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  return (
    <div className={`${!isOpen && 'hidden'} relative`}>
      <div className="fixed inset-0 bg-black bg-opacity-25 overflow-auto z-40 h-screen">
        <div className="p-6 bg-white w-full h-fit max-w-md mx-auto absolute inset-x-0 top-12 rounded-md z-50 shadow-xl">
          {icon && <Button variant="primary" size="lg" icon={icon} iconPosition="only" className="mb-5 rounded-full" />}
          <h2 className="text-lg font-medium text-gray-900 mb-2">{title}</h2>
          <p className="text-md font-normal text-gray-700 mb-5">{text}</p>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal
