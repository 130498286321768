import { ReactChild, useState } from 'react'
import Card from '../Card'
import MovementList from './MovementList'
import { useMovements } from '../../customHooks/useMovements'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { sendMailToUserWithTransactions } from '../../service/user'
import { IUserTransactionsParams, EMovementsFilter, IRadioButton } from '../../common/types'
import Modal from '../Modal'
import FeatherIcon from 'feather-icons-react'
import { Button } from '../Button'
import DateInput from '../Input/DateInput'
import { parseDate } from '../../common/utils'


const Movements = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [radioChecked, setRadioChecked] = useState<number>(0)
  const [formHasError, setFormHasError] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  // Query
  const { isLoading, isError, data: movements } = useMovements()
  const { t } = useTranslation()

  const radioButtons: IRadioButton[] = [
    { id: EMovementsFilter[1], value: EMovementsFilter.ALL, label: t('movements.accountStatusModal.allMovementsLabel') },
    { id: EMovementsFilter[2], value: EMovementsFilter.TODAY, label: t('movements.accountStatusModal.todayLabel') },
    { id: EMovementsFilter[3], value: EMovementsFilter.DATE_RANGE, label: t('movements.accountStatusModal.dateRangeLabel') }
  ]

  const { mutate, isLoading: sendingEmail } = useMutation(sendMailToUserWithTransactions, {
    onSuccess: (result) => {
      setIsOpenModal(false)
    }
  })

  const cleanState = () => {
    setStartDate('')
    setEndDate('')
    setRadioChecked(0)
    setFormHasError(false)
    setErrorMessage(undefined)
  }

  const handleOpenModal = () => {
    cleanState()
    setIsOpenModal(true)
  }
  const handleCloseModal = () => {
    cleanState()
    setIsOpenModal(false)
  }

  const handleRadioChecked = (event) => {
    const value = event.target.value
    setRadioChecked(+value)
  }

  const handleStartDateChange = ({ target: { value } }) => setStartDate(value)

  const handleEndDateChange = ({ target: { value } }) => setEndDate(value)

  const handleSubmit = async (event) => {
    event.preventDefault()
    setErrorMessage('')
    const body: IUserTransactionsParams = {}
    const today = new Date()
    const currentDateFormatted = today.toISOString().split('T')[0]
    let hasError = false
    if (radioChecked === EMovementsFilter.TODAY)
      body.dateStart = currentDateFormatted

    if (radioChecked === EMovementsFilter.DATE_RANGE) {
      body.dateStart = parseDate(startDate)
      body.dateEnd = parseDate(endDate)
      if (body.dateEnd > currentDateFormatted || body.dateStart > currentDateFormatted) {
        setFormHasError(true)
        setErrorMessage(t('movements.accountStatusModal.periodDateInvalid'))
        hasError = true
      }
      if (body.dateEnd < body.dateStart) {
        setFormHasError(true)
        setErrorMessage(t('movements.accountStatusModal.startDateGreaterThanEndDate'))
        hasError = true
      }
    }

    if (!hasError) {
      mutate(body, {
        onSuccess: () => {
          setIsOpenModal(false)
          cleanState()
        }
      })
    }
  }

  if (isError) {
    return (
      <div>
        {t("movements.errorLoadingMessage")}
      </div>
    )
  }

  return (
    <div className="mt-2 mb-16">
      {
        !isLoading &&
        <Card componentTranslationKey="movements" >
          <div className="relative">
            <div className="absolute bottom-6 right-4">
              <button className="flex flex-row items-center rounded-sm border h-10 px-4 text-gray-700 hover:bg-gray-100 hover:text-gray-600 text-sm" onClick={handleOpenModal}>
                <FeatherIcon icon='mail' color='rgb(55 65 81)' size={16} />
                <span className="ml-2">{t("movements.accountStatus")}</span>
              </button>
            </div>
          </div>
          <MovementList movements={movements!} />
          <Modal
            title="Obtener estado de cuenta"
            text="Te enviaremos a tu email el estado de cuenta con la configuración que eligiste"
            icon="mail"
            isOpen={isOpenModal}
            onClickClose={handleCloseModal}>
            <form onSubmit={(event) => handleSubmit(event)}>
              <div className="flex flex-col">
                {radioButtons.map(radio => (
                  <div key={radio.id} className="my-2">
                    <label htmlFor={radio.id} className="flex items-center text-gray-700 text-sm">
                      <input
                        id={radio.id}
                        type="radio"
                        name="dateFilter"
                        onChange={(event) => handleRadioChecked(event)}
                        checked={radioChecked === radio.value}
                        value={radio.value}
                        className="mr-1"
                      />
                      {radio.label}
                    </label>
                  </div>
                ))}
                {
                  radioChecked === EMovementsFilter.DATE_RANGE && <div className="flex mt-4">
                    <div className="flex-1 flex-col mr-4">
                      <label className="block mb-1 text-sm text-gray-700">{t("movements.accountStatusModal.startDate")}</label>
                      <DateInput
                        key="startDate"
                        name="start_date"
                        type="text"
                        placeholder='dd/mm/aaaa'
                        autoComplete='off'
                        required
                        className="w-full h-10 px-4 text-base text-gray-900 placeholder-gray-400 border rounded-sm focus:shadow-outline"
                        onChange={handleStartDateChange}
                        value={startDate}
                      />
                    </div>
                    <div className="flex-1 flex-col">
                      <label className="block mb-1 text-sm text-gray-700">{t("movements.accountStatusModal.endDate")}</label>
                      <DateInput
                        key="endDate"
                        name="end_date"
                        type="text"
                        placeholder='dd/mm/aaaa'
                        autoComplete='off'
                        required
                        className="w-full h-10 px-4 text-base text-gray-900 placeholder-gray-400 border rounded-sm focus:shadow-outline"
                        onChange={handleEndDateChange}
                        value={endDate}
                      />
                    </div>
                  </div>
                }
                {
                  formHasError &&
                  <div className="flex mt-3">
                    <span className="font-normal tracking-wide text-red-500 text-sm">
                      {errorMessage}
                    </span>
                  </div>
                }
              </div>
              <div className="flex mt-8">
                <Button variant="secondary-gray" label={t("movements.accountStatusModal.cancelButton") as ReactChild} onClick={handleCloseModal} className="flex-1 mr-4" disabled={sendingEmail} />
                <Button variant="primary" label={t("movements.accountStatusModal.confirmButton") as ReactChild} className="flex-1" type="submit" isLoading={sendingEmail} />
              </div>
            </form>
          </Modal>
        </Card>
      }
    </div >
  )
}

export default Movements
