import React from 'react'
import { ISwapForm, IUser, ISwapResponse } from '../../common/types'
import SwapContent from './SwapContent'

interface Props {
  data: ISwapForm
  tokenPrice: number
  toggle: () => void
  //TODO: Se agrego el signo ? para que onClick no sea obligatorio, para la version de Wallet sin Swap
  onClick?: () => Promise<ISwapResponse>
  feeAndTaxesDetails: any
  user: IUser
}

const SwapPreview = ({ data, tokenPrice, feeAndTaxesDetails, user, toggle, onClick }: Props) => {
  return (
    <SwapContent
      data={data}
      feeAndTaxesDetails={feeAndTaxesDetails}
      tokenPrice={tokenPrice}
      user={user}
      isPreview={true}
      handleExecuteTransaction={onClick}
      toggle={toggle}
    />
  )
}

export default SwapPreview
