import { http } from '../http-common'
import { IMovement, IApiResponse } from '../common/types'

export const getMovements = (): Promise<IMovement[] | undefined> => {
  return http.get<IApiResponse<IMovement[]>>('/movements').then((resp) => {
    const dataResult: IMovement[] | undefined = resp.data.data
    return dataResult
  })
}

export const getMovement = ({ queryKey }): Promise<IMovement | undefined> => {
  const [, url, id] = queryKey
  return http.get<IApiResponse<IMovement>>(`/movements/${url}/${id}`).then((resp) => {
    const dataResult: IMovement | undefined = resp.data.data
    return dataResult
  })
}
