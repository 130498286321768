import {
    calculatePagerMetadata
} from './PagingHelper'
import Icon from '../Icon'
import classNames from 'classnames'
import styles from './PagingBar.module.css'
import { useTranslation } from 'react-i18next'

interface Props {
    canPreviousPage: boolean;
    canNextPage: boolean;
    pageCount: number;
    pageIndex: number;
    pageSize: number;
    totalResults: number;
    gotoPage: (page: number) => void
    nextPage: () => void,
    previousPage: () => void
}

const PagedBar = ({
    canPreviousPage,
    canNextPage,
    pageCount,
    pageIndex,
    gotoPage,
    nextPage,
    previousPage
}: Props) => {

    const pagingMetadata = calculatePagerMetadata(canPreviousPage, canNextPage, pageIndex + 1, pageCount)
    const { t } = useTranslation()

    return (
        <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 text-sm">
            <div className="flex-1 flex justify-between sm:hidden">
                <button type="button" className={classNames(`${styles.pagingButton}`, { [styles.disabledButton]: !canPreviousPage })} onClick={() => previousPage()}>
                    {t("pagedBar.previous")}
                </button>
                <button type="button" className={classNames(`ml-3 ${styles.pagingButton}`, { [styles.disabledButton]: !canNextPage })} onClick={() => nextPage()}>
                    {t("pagedBar.next")}
                </button>
            </div>

            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <button type="button" className={classNames(`${styles.pagingButton}`, { [styles.disabledButton]: !canPreviousPage })} onClick={() => previousPage()}>
                    <Icon icon="arrow_left" size="xs" />
                    <span className="ml-2">{t("pagedBar.previous")}</span>
                </button>
                <div>
                    <nav className="relative z-0 inline-flex rounded-md" aria-label="Pagination">
                        {
                            pagingMetadata.pages.map(page => {
                                return (
                                    <button key={page} className={classNames(`${styles.paginationItem}`, { [styles.paginationItemActive]: pageIndex === page - 1 })} onClick={() => gotoPage(page - 1)}>
                                        {page}
                                    </button>
                                )
                            })
                        }
                    </nav>
                </div>
                <button type="button" className={classNames(`ml-3 ${styles.pagingButton}`, { [styles.disabledButton]: !canNextPage })} onClick={() => nextPage()}>
                    <span className="mr-2">{t("pagedBar.next")}</span>
                    <Icon icon="arrow_right" size="xs" />
                </button>
            </div>
        </div>
    )
}

export default PagedBar