import { useRef } from 'react'
import { Column } from 'react-table'
import { Table } from '../Table/Table'
import { Link } from 'react-router-dom'
import { IMovement, EMovementState, ESwapState } from '../../common/types'
import { movementTypes, movementStateStyle } from '../../common/const'
import Icon from '../Icon'
import MovementState from './MovementState'
import { formatNumber } from '../../common/utils'
import { useTranslation } from 'react-i18next'

interface Props {
  movements: IMovement[]
}
interface ExtendedMovement extends IMovement {
  movementDetail: React.ReactNode
}

const MovementList = ({ movements }: Props) => {

  const movementsRef = useRef<IMovement[]>()
  movementsRef.current = movements

  const MovementsMap = movements!.map((mov) => { return { ...mov, movementDetail: null } })
  const data: ExtendedMovement[] = MovementsMap
  const movementsExtended: ExtendedMovement[] | undefined = movements?.map((mov) => { return { ...mov, movementDetail: null } })

  const { t } = useTranslation()

  const translatorMovementState = {
    'In progress': t("movements.states.inProgress") as string,
    Completed: t("movements.states.completed") as string,
    Expired: t("movements.states.expired") as string,
  }

  const movementState = (state) => state === EMovementState.COMPLETED || +state === ESwapState.DONE ? EMovementState.COMPLETED :
    state === EMovementState.EXPIRED ? EMovementState.EXPIRED : EMovementState.IN_PROGRESS

  const columns: Column<ExtendedMovement>[] = (
    [
      {
        Header: t("movements.columns.transactionType") as string,
        accessor: row => t("movements.transactionType." + row.transactionDisplayName) as string,
        Cell: props => <div className="min-w-full w-36">{t("movements.transactionType." + props.row.original.transactionDisplayName) as string}</div>
       
      },
      {
        Header: t("movements.columns.amount") as string,
        accessor: row => `${formatNumber(row.cryptoAmount)} ${row.cryptoCurrency}`
      },
      {
        id: '3',
        Header: t("movements.columns.valorization") as string,
        accessor: row => `${formatNumber(row.transactionType! === movementTypes.swap.transactionType ? row.cryptoAmount! * row.currencyToUSDRate! * row.usdToLocalCurrencyRate! : row.localCurrencyAmount, false)} ${row.localCurrency}`,
        Cell: (props) => {
          const { cryptoAmount, currencyToUSDRate, usdToLocalCurrencyRate, transactionType, localCurrencyAmount } = props.row.original
          const valorization = transactionType === movementTypes.swap.transactionType && cryptoAmount > 0 && currencyToUSDRate > 0 && usdToLocalCurrencyRate > 0
            ?
            cryptoAmount * currencyToUSDRate * usdToLocalCurrencyRate
            :
            localCurrencyAmount

          const localCurrency = props.row.original.localCurrency
          return (
            <div className="w-32">
              {
                +valorization !== 0 ?
                <div>
                  {formatNumber(valorization, false)} {localCurrency}
                </div> : "-"
              }
            </div>
          )
        }
       
      },
      {
        Header: t("movements.columns.user") as string,
        accessor: 'user'
      },
      {
        id: 'date',
        Header: t("movements.columns.date") as string,
        accessor: 'date',
        Cell: (props) => {
          const { date } = props.row.original
          const dateFormatted = date.split(' ')
          return (
            <div className="flex flex-col font-normal text-sm">
              <span className="text-gray-900">{dateFormatted[0]}</span>
              {dateFormatted[1] && <span className="text-gray-500">{dateFormatted[1]}</span>}
            </div>
          )
        }
      },
      {
        Header: t("movements.columns.state") as string,
        accessor: row => translatorMovementState[movementState(row.state)],
        Cell: (props) => {
          const status = props.row.original.state
          return <div className="flex flex-row justify-center lg:justify-start font-normal text-sm">
            <MovementState
              state={translatorMovementState[movementState(status)]}
              style={movementStateStyle[movementState(status)]}
            />
          </div>
        }
      },
      {
        accessor: 'movementDetail',
        Cell: (props) => {
          const id = props.row.original.id
          const transactionType = props.row.original.transactionType
          const url = props.row.original.urlToDetail
          const canShowDetailButton = transactionType === movementTypes.swap.transactionType
          return (
            <>
              {
                canShowDetailButton &&
                <div className="flex flex-row justify-center lg:justify-start">
                  <Link to={`movements/${url}/${id}`} className="flex items-center hover:text-primary">
                    <Icon icon="detail" size="xs" cssClass="mr-1" />
                    <span>{t("movements.detail")}</span>
                  </Link>
                </div>
              }
            </>
          )
        }
      }
    ]
  )

  return (
    <div>
      {
        movementsExtended && <Table data={data} columns={columns} />
      }
    </div>
  )
}

export default MovementList
