import Main from './Main'
import Movements from '../components/Movements/Index'

const Home = () => {
  return (
    <div className="container max-w-5xl px-4 lg:px-0">
      <Main />
      <div className="flex flex-col w-full mt-8 xs:overflow-x-scroll">
        <Movements />
      </div>
    </div>
  )
}

export default Home
