import { http } from '../http-common'
import { ICurrencyRate, IApiResponse, IFee } from '../common/types'

export const getRates = (): Promise<ICurrencyRate[] | undefined> => {
  return http.get<IApiResponse<ICurrencyRate[]>>('/currency/rates').then((resp) => {
    const dataResult: ICurrencyRate[] | undefined = resp.data.data
    return dataResult
  })
}

export const getFees = (): Promise<IFee[] | undefined> => {
  return http.get<IApiResponse<IFee[]>>('/fees').then((resp) => {
    const dataResult: IFee[] | undefined = resp.data.data
    return dataResult
  })
}
