import type { CurrenciesMap, ILink } from './types'

export const CRYPTO_DECIMALS = 10000
export const SWAP_ERROR_TOLERANCE = 0.05

export const currencies: CurrenciesMap = {
  USD: {
    symbol: 'USD',
    type: 'FIAT',
    icon: 'usd'
  },  
  'USD Soja': {
    symbol: 'USD Soja',
    type: 'FIAT',
    icon: 'usd'
  },
  ARS: {
    symbol: 'ARS',
    type: 'FIAT',
    icon: 'ars'
  },
  SOYA: {
    symbol: 'SOYA',
    type: 'CRYPTO',
    icon: 'soya',
    contractName: 'agrotoken'
  },
  CORA: {
    symbol: 'CORA',
    type: 'CRYPTO',
    icon: 'cora',
    contractName: 'cora'
  },
  WHEA: {
    symbol: 'WHEA',
    type: 'CRYPTO',
    icon: 'whea',
    contractName: 'whea'
  },
  BITCOIN: {
    symbol: 'BITCOIN',
    type: 'CRYPTO',
    icon: 'bitcoin'
  },
  USDC: {
    symbol: 'USDC',
    type: 'CRYPTO',
    icon: 'usdc'
  }
}

export const LINKS: ILink[] = [
  {
    id: 1,
    name: 'Mis solicitudes',
    url: '/app/requests',
    active: false,
    translationKey: 'myRequests'
  },
  {
    id: 2,
    name: 'Billetera',
    url: '',
    active: true,
    translationKey: 'wallet'
  },
  {
    id: 3,
    name: 'Tokenización',
    url: '/tokenization/0',
    active: false,
    translationKey: 'tokenization'
  }
]

export const cryptos = { SOYA: 'value_agrosoy_usd', CORA: 'value_cora_usd', WHEA: 'value_whea_usd' }
export const fiats = { ARS: 'value_ars' }

export const feeParams = {
  agrotokenFee: 'agrotoken_swap_fee',
  tradeFee: 'exchange_swap_trade_fee',
  cashOut: 'exchange_swap_cashout_fee',
  exchangeFee: 'exchange_swap_fee',
  advanceFee: 'agrotoken_swap_advance_fee',
  tax: 'agrotoken_swap_tax_fee'
}

export const cashOutTypes: string[] = ['exchange_swap_cashout_fee', 'agrotoken_swap_advance_fee']
export const exchangeValues: string[] = ['exchange_swap_trade_fee', 'exchange_swap_cashout_fee']

export const feesAndTaxes = {
  agrotokenFee: {
    name: 'agrotoken_swap_fee',
    description: 'Fee Agrotoken',
    value: 0,
    currencyFromAmount: 0,
    currencyToAmount: 0,
    order: 1
  },
  tradeFee: {
    name: 'exchange_swap_trade_fee',
    description: 'Fee Exchange',
    value: 0,
    currencyFromAmount: 0,
    currencyToAmount: 0,
    order: 2
  },
  cashOutFee: {
    name: 'exchange_swap_cashout_fee',
    description: 'Fee Exchange',
    value: 0,
    currencyFromAmount: 0,
    currencyToAmount: 0,
    order: 3
  },
  advanceFee: {
    name: 'agrotoken_swap_advance_fee',
    description: 'Tasa de adelanto',
    value: 0,
    currencyFromAmount: 0,
    currencyToAmount: 0,
    order: 4
  },
  taxes: {
    name: 'agrotoken_swap_tax_fee',
    description: 'Impuestos',
    value: 0,
    currencyFromAmount: 0,
    currencyToAmount: 0,
    order: 5
  }
}

export const paramWithRateList = {
  value_ars: {
    currencyFrom: {
      symbol: 'USD',
      type: 'CRYPTO',
      icon: 'usdc'
    },
    currencyTo: {
      symbol: 'ARS',
      type: 'FIAT',
      icon: 'usd'
    },
    value: null
  },
  value_soja_ars: {
    currencyFrom: {
      symbol: 'USD Soja',
      type: 'FIAT',
      icon: 'usdc'
    },
    currencyTo: {
      symbol: 'ARS',
      type: 'FIAT',
      icon: 'usd'
    },
    value: null
  },
  value_agrosoy_usd: {
    currencyFrom: {
      symbol: 'SOYA',
      type: 'CRYPTO',
      icon: 'soya'
    },
    currencyTo: {
      symbol: 'USD',
      type: 'FIAT',
      icon: 'usd'
    },
    value: null
  },
  value_cora_usd: {
    currencyFrom: {
      symbol: 'CORA',
      type: 'CRYPTO',
      icon: 'cora'
    },
    currencyTo: {
      symbol: 'USD',
      type: 'FIAT',
      icon: 'usd'
    },
    value: null
  },
  value_whea_usd: {
    currencyFrom: {
      symbol: 'WHEA',
      type: 'CRYPTO',
      icon: 'whea'
    },
    currencyTo: {
      symbol: 'USD',
      type: 'FIAT',
      icon: 'usd'
    },
    value: null
  },
}

export const movementTypes = {
  paymentReceived: {
    name: 'payment_received',
    transactionType: 'payment',
    displayName: 'Pago Realizado',
    url: 'payments'
  },
  paymentInKind: {
    name: 'payment_kind',
    transactionType: 'payment_kind',
    displayName: 'Registro de Pago en Especie',
    url: 'payments'
  },
  paymentSent: {
    name: 'payment_sent',
    transactionType: 'payment',
    displayName: 'Pago Enviado',
    url: 'payments'
  },
  tokenization: {
    name: 'tokenization',
    transactionType: 'tokenization',
    displayName: 'Tokenización',
    url: 'tokenizations'
  },
  destokenization: {
    name: 'destokenization',
    transactionType: 'destokenization',
    displayName: 'Destokenización',
    url: 'tokenizations'
  },
  fee: {
    name: 'fee',
    transactionType: 'fee',
    displayName: 'fee',
    url: 'fees'
  },
  swap: {
    name: 'swap',
    transactionType: 'swap',
    displayName: 'Conversión',
    url: 'swaps'
  },
  loan_granted: {
    name: 'loan_granted',
    transactionType: 'loan',
    displayName: 'Préstamo Colateralizado',
    url: 'user-loans'
  },
  loan_reimbursement: {
    name: 'loan_reimbursement',
    transactionType: 'loan',
    displayName: 'Préstamo Reembolsado',
    url: 'user-loans'
  }
}

export const movementStateStyle = {
  'In progress': 'bg-blue-100 text-blue-700 rounded-2xl w-max ',
  Completed: 'bg-green-100 text-green-700 rounded-2xl w-max',
  Expired: 'bg-red-100 text-red-700 rounded-2xl w-max'
}

export const API_BASE: string = '/api/v1'
export const LOGIN_PATH: string = '/auth/login'
export const getLoginPath: Function = () => `${API_BASE}${LOGIN_PATH}`
// TODO mejorar esto, estoy contemplando que no metan como un parametro tipo ?/webhook/ o algo asi pero no se si es muy seguro
export const isWHPath: Function = (path: string) => path.toLowerCase().indexOf('/webhook/') > 0 && path.toLowerCase().indexOf('?') === -1
export const isLoginPath: Function = (path: string) => path === getLoginPath()
export const ACCESS_TOKEN_KEY: string = 'accessToken'
