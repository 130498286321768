export const TRANSLATIONS_ES = {
  header: {
    links: {
      myRequests: 'Mis solicitudes',
      wallet: 'Billetera',
      tokenization: 'Tokenización',
      accountProfile: 'Mi cuenta'
    }
  },
  rates: {
    title: 'Cotizaciones',
    errorLoadingMessage: 'Se encontró un error, las cotizaciones no pudieron ser cargadas ...'
  },
  portfolio: {
    title: 'Billetera',
    description: 'Total del portafolio valorizado en '
  },
  wallet: {
    cardTitle: 'Billetera',
    cardDescription: 'Balance',
    columns: {
      active: 'Activo',
      tokenPrice: 'Precio Token',
      balance: 'Balance'
    }
  },
  swap: {
    initial: {
      cardTitle: 'Convertir tokens',
      cardDescription: 'Convierte tus tokens de una manera fácil'
    },
    preview: {
      cardTitle: 'Conversión de tokens',
      cardDescription: 'Vista previa de la transacción'
    },
    transaction: {
      cardTitle: 'Transacción enviada exitosamente',
      cardDescription: 'Te enviaremos un mail con toda la información de la transacción'
    },
    form: {
      inputSellLabel: 'Vender',
      inputReceiveLabel: 'Recibir',
      amountToSellErrorMessage: 'Saldo insuficiente',
      feesIncludedMessage: 'Este monto ya tiene las comisiones incluidas',
      instantRadioButton: 'Instántaneo',
      inSevenDaysRadioButton: 'En 7 días',
      deposit: 'Depósito',
      previewButton: 'Vista previa de la transacción',
      balance: 'Balance',
      placeholderSelect: 'Seleccionar token',
      customDefaultOption: {
        token: 'Token',
        balance: 'Saldo'
      }
    },
    content: {
      tokensToSell: 'Tokens a vender:',
      tokensSold: 'Tokens vendidos:',
      conversion: 'Conversión:',
      agrotoken_swap_fee: 'Fee Agrotoken',
      exchange_swap_fee: 'Fee Exchange',
      agrotoken_swap_advance_fee: 'Tasa de adelanto',
      agrotoken_swap_tax_fee: 'Impuestos',
      toReceive: 'A recibir:',
      totalToReceive: 'Total a recibir:',
      willBeDeposited: 'Serán depositados en:',
      willReceiveIn: 'Los recibirás en:',
      executeTransaction: 'Ejecutar transacción',
      cancel: 'Cancelar',
      goToStart: 'Volver a Inicio',
      netAmount: 'Monto Nominal:',
      cbu: 'CBU '
    },
    errorMessage: 'No se pudo ejecutar la transacción, por favor intente mas tarde.'
  },
  movements: {
    cardTitle: 'Movimientos',
    cardDescription: 'Todos los movimientos de tu billetera',
    errorLoadingMessage: 'Se encontró un error cargando los movimientos...',
    tooltipTextValorization: 'El cálculo esta hecho según el precio del token en la fecha del movimiento. Los valores no incluyen descuentos correspondientes',
    columns: {
      transactionType: 'Tipo de transacción',
      amount: 'Monto',
      valorization: 'Valorización',
      user: 'Usuario',
      date: 'Fecha',
      state: 'Estado'
    },
    detail: 'Detalle',
    states: {
      inProgress: 'En curso',
      completed: 'Completado',
      expired: 'Expirado'
    },
    movementDetail: {
      errorLoadingMessage: 'Se encontró un error, el detalle de movimiento no pudo ser cargado ...',
      tokensConvert: 'Conversión de tokens',
      transactionInformation: 'Información de la transacción',
      state: 'Estado:',
      transaction: 'Transacción:',
      date: 'Fecha:',
      transactionDetail: 'Detalle de la transacción',
      tokensToSell: 'Tokens a vender:',
      conversion: 'Conversión:',
      nominalValueToReceive: 'Valor nominal a recibir:',
      agrotokenFee: 'Fee Agrotoken',
      exchangeFee: 'Fee Exchange',
      advanceFee: 'Tasa de adelanto (Instantáneo)',
      taxes: 'Impuestos',
      toReceive: 'A recibir:',
      depositedIn: 'Depositados en:',
      willBeDepositedIn: 'Serán depositados en:',
      wallet: 'Billetera',
      issueTransactionMessage: 'Tuviste un problema con esta transacción?',
      sendAnEmail: 'Envíanos un mail'
    },
    transactionType: {
      payment_received: 'Pago realizado',
      payment_kind: 'Registro de pago en especie',
      payment_sent: 'Pago enviado',
      swap: 'Conversión',
      tokenization: 'Tokenización',
      destokenization: 'Destokenización',
      fee: "Comisión",
      loan_granted: "Préstamo colateralizado",
      loan_reimbursement: "Reembolso por préstamo",
    },
    accountStatus: 'Obtener estado de cuenta',
    accountStatusModal: {
      title: 'Obtener estado de cuenta',
      description: 'Te enviaremos a tu email el estado de cuenta con la configuración que eligiste',
      allMovementsLabel: 'Todos los movimientos',
      todayLabel: 'Hoy',
      dateRangeLabel: 'Un rango de fechas',
      cancelButton: 'Cancelar',
      confirmButton: 'Confirmar',
      periodDateInvalid: 'Período de fechas inválido',
      startDateGreaterThanEndDate: 'La fecha final no puede ser anterior a la fecha de inicio',
      startDate: 'Fecha de inicio',
      endDate: 'Fecha final'
    }
  },
  emptyState: {
    balances: {
      title: 'No tenés ningún activo',
      description: 'No tienes ningún activo en tu billetera.'
    },
    swap: {
      title: 'No tenés saldo suficiente para convertir',
      description: 'Para poder convertir tokens, es necesario que tengas al menos un activo en tu billetera.'
    },
    movements: {
      title: 'Podrás ver los movimientos de tu billetera aquí',
      description: 'Todavía no tenés ningún movimiento, una vez que hagas alguno, aparecerán acá.'
    },
    disabledSwap: {
      title: '¡Próximamente!',
      description: 'Estamos trabajando en una nueva funcionalidad para que puedas intercambiar tus tokens..'
    }
  },
  search: {
    placeholder: 'Buscar'
  },
  filterBar: {
    all: 'Ver todos',
    received: 'Recibidos',
    sent: 'Enviados'
  },
  pagedBar: {
    previous: 'Anterior',
    next: 'Siguiente'
  },
  mainErrorMessage: 'Se encontró un error...'
}
