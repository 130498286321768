import React, { ChangeEvent } from 'react'
import InputMask from 'react-input-mask'

interface Props {
  name: string
  type?: string
  placeholder?: string
  autoComplete?: string
  required?: boolean
  className?: string
  value?: string
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void
}

const DateInput = ({ name, type, placeholder, autoComplete, required, className, value, onChange }: Props) => {
  return (
    <InputMask 
      mask='99/99/9999' 
      name={name}
      type={type}
      placeholder={placeholder}
      required={required}
      className={className}
      autoComplete={autoComplete}
      value={value} 
      onChange={onChange}
    />
  )
}

export default DateInput;

