import React from 'react'
import Icon from './Icon'

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
}

const SearchBar = ({ onChange, placeholder }: Props) => {
  return (
    <div className="relative">
      <Icon icon="search" size="sm" cssClass="absolute left-0 top-0 mt-3 mx-3" />
      <input placeholder={placeholder} onChange={onChange} className="w-full sm:w-96 h-10 pr-4 pl-10 text-md font-normal text-gray-700 placeholder-gray-500 border rounded-sm focus:shadow-outline" />
    </div>
  )
}

export default SearchBar
