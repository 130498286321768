import React, { useEffect, useState } from 'react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import PulseLoader from 'react-spinners/PulseLoader'
import * as dotenv from 'dotenv'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import Layout from './components/Layout'
import Content from './components/Content'
import { queryClient } from './queryClient'
import { AuthManager } from './http-common'

import './assets/tailwind.css'

dotenv.config()

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const App = () => {
  const [isAuth, setIsAuth] = useState(false)

  useEffect(() => {
    async function auth() {
      const res = await AuthManager.login()
      if (res) {
        AuthManager.setLocalAccessToken(res.accessToken)
        setIsAuth(true)
      }
    }
    auth()
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      {isAuth && (
        <div className="app mb-8">
          <Layout>
            <Content />
          </Layout>
        </div>
      )}
      {!isAuth && (
        <div className="flex h-100 h-screen items-center justify-center w-100">
          <PulseLoader color="#57D972" size={12} margin={2} />
        </div>
      )}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
