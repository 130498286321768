import React from 'react'
import Navbar from './Navbar/Index'
import Rates from './Rates'
import { LINKS } from '../common/const'

interface Props {
  children?: React.ReactNode
}

const Layout = ({ children }: Props) => {
  return (
    <React.Fragment>
      <Navbar links={LINKS} />
      <Rates />
      {children}
    </React.Fragment>
  )
}

export default Layout
