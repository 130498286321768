import React from 'react'
import Portfolio from './Portfolio'
import Balances from './Balance/Index'
import Swap from './Swap/Index'
import PulseLoader from "react-spinners/PulseLoader"
import { useFees } from '../customHooks/useFees'
import { useBalances } from '../customHooks/useBalances'
import { useRates } from '../customHooks/useRates'
import { useUser } from '../customHooks/useUser'
import { roundNumber } from '../common/utils'
import { useTranslation } from 'react-i18next'
import { IBalance } from '../common/types'

const Main = () => {

  const { t } = useTranslation()
  // Query
  const { isLoading: loadingUser, isError: errorUser, data: user } = useUser()
  const { isLoading: loadingBalances, isError: errorBalances, data: balances } = useBalances()
  const { isLoading: loadingFees, isError: errorFees, data: fees } = useFees()
  const { isLoading: loadingRates, isError: errorRates, data: rates } = useRates()

  if (loadingBalances || loadingFees || loadingRates || loadingUser) {
    return (
      <div className="flex flex-row justify-center items-center mt-48 text-sm">
        <PulseLoader color="#57D972" size={15} margin={2} />
      </div>
    )
  }

  if (errorBalances || errorFees || errorRates || loadingUser || errorUser) {
    return (
      <div>
        {t("mainErrorMessage")}
      </div>
    )
  }

  const usdToLocalCurrency = rates!.find((rate) => rate.currencyFrom.symbol === 'USD' && rate.currencyTo.symbol === process.env.REACT_APP_LOCAL_CURRENCY)?.value
  const usdSojaToLocalCurrency = rates!.find((rate) => rate.currencyFrom.symbol === 'USD Soja' && rate.currencyTo.symbol === process.env.REACT_APP_LOCAL_CURRENCY)?.value

  const mapBalancesToProps = balances!.map(balance => {
    const convertionRate = balance.currencySymbol === "SOYA" ? usdSojaToLocalCurrency : usdToLocalCurrency;
    return ({
      ...balance,
      price: roundNumber(+(rates!.find((rate) => rate.currencyFrom.symbol === balance.currencySymbol)?.value! * convertionRate!))
    })
  })

  return (
    <>
      <Portfolio balances={mapBalancesToProps} />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <Balances balances={mapBalancesToProps!} />
        <Swap balances={mapBalancesToProps!} fees={fees!} user={user!} />
      </div>
    </>
  )
}

export default Main
