import { Request } from 'express'

export interface IApiResponse<T> {
  errorMessage?: string
  responseCode?: number
  data?: T
}

export type FiatCurrencySymbol = 'USD' | 'ARS' | 'USD Soja'
export type CryptoCurrencySymbol = 'SOYA' | 'CORA' | 'WHEA' | 'USDC' | 'BITCOIN'
export type CurrencyType = 'FIAT' | 'CRYPTO'
export type CurrencySymbol = FiatCurrencySymbol | CryptoCurrencySymbol
export type IconType =
  | 'soya'
  | 'cora'
  | 'whea'
  | 'usd'
  | 'ars'
  | 'single_angle_down'
  | 'arrow_right'
  | 'arrow_right_white'
  | 'arrow_left'
  | 'arrow_up'
  | 'search'
  | 'detail'
  | 'calendar'
  | 'hashtag'
  | 'hand_up'
  | 'greater_than'
  | 'bitcoin'
  | 'usdc'
  | 'arrow_down'
  | 'success'

export interface ICurrency {
  symbol: CurrencySymbol
  icon: IconType
  type: CurrencyType
  contractName?: string
}

export type CurrenciesMap = { [key in CurrencySymbol]: ICurrency }

export interface ICurrencyRate {
  currencyFrom: ICurrency
  currencyTo: ICurrency
  value: number | null
}

export interface ILink {
  id: number
  name: string
  url: string
  active: boolean
  translationKey: string
}

export type ColumnAlign = 'left' | 'center' | 'right'

export interface IBalanceHeaderColumn {
  translationKey: string
  helpDescription?: string
  sort?: boolean
  align: ColumnAlign
}

export interface ICurrencyWithBalance {
  currencySymbol: CryptoCurrencySymbol
  balance: number
}

export interface IBalance {
  currencySymbol: CryptoCurrencySymbol
  currencyDescription: string
  priceCurrency: FiatCurrencySymbol
  balance: number
  icon: IconType
  price?: number
}

export interface IOptionSelect {
  value: CurrencySymbol | FiatCurrencySymbol | null
  label: CurrencySymbol | FiatCurrencySymbol | null
  icon: IconType | null | undefined
  balance?: number
  disabled?: boolean
}

export type InputType = 'text' | 'number'

export type Color = 'primary' | 'secondary'

export interface ISwapForm {
  from: CurrencySymbol | ''
  to: CurrencySymbol | ''
  amountToSell: number | undefined
  amountToReceive: string
  depositType: number
}

export interface IMovement {
  id: number
  transactionType: string
  transactionDisplayName: string
  urlToDetail: string
  cryptoAmount: number
  cryptoCurrency: CurrencySymbol
  localCurrencyAmount: number
  localCurrency: CurrencySymbol
  user: string | undefined
  state: string
  date: string
  transaction?: string
  currencyToUSDRate?: number // SOYA_USD CORA_USD
  usdToLocalCurrencyRate?: number // USD_ARS  // USD_BRZ
  agrotokenFee?: number
  exchangeFee?: number // %
  advanceFee?: number
  tax?: number
  agrotokenFeeAmount?: number // % aplicado
  exchangeFeeAmount?: number
  advanceFeeAmount?: number
  taxAmount?: number
  netAmount?: number
  isInstant?: boolean
  cbu?: string
}
export interface IRadioButton {
  id: string
  value: string | number
  label: string
}
export interface IFee {
  id: number
  name: string
  value: number
}

export interface IFeeExtended {
  name: string
  value: number
  description: string
  currencyFromAmount: number
  currencyToAmount: number
  order: number
}

export interface ISwapParams {
  from: CurrencySymbol
  to: CurrencySymbol
  amountRequested: number
  netAmountLocalCurrency: number
  isInstant: boolean
}

export interface ISwapResult {
  success: boolean // TODO: review
}

export enum ESwapState {
  NEW = 1,
  TRANSFER_TO_FARMER_PENDING = 2,
  TRANSFER_TO_PSP_PENDING = 3,
  DONE = 4
}

export enum ESwapTransactionState {
  NEW = 1,
  CONFIRMED = 2,
  REJECTED = 3
}

export enum ENetworks {
  ETHEREUM = 'ethereum',
  POLYGON = 'polygon',
  ALGO = 'algorand'
}

export enum EMovementState {
  IN_PROGRESS = 'In progress',
  COMPLETED = 'Completed',
  EXPIRED = 'Expired'
}
export interface ISwapTransaction {
  id?: number
  swapId: number
  hash?: string
  fromWallet: string
  toWallet: string
  amount: number
  network: ENetworks
  currency: CryptoCurrencySymbol
  state: ESwapTransactionState
  step: ESwapState
}

export interface ISwapUserInfo {
  id: string
  name: string
  cbu: string
}
export interface ISwap {
  id?: number
  transaction?: string
  user?: ISwapUserInfo
  from: CryptoCurrencySymbol
  to: CurrencySymbol
  currencyToUSDRate: number // SOYA_USD CORA_USD
  usdToLocalCurrencyRate: number // USD_ARS  // USD_BRZ
  amount: number
  state: ESwapState
  created?: string
  agrotokenFee: number
  exchangeFee: number // %
  advanceFee: number
  tax: number
  agrotokenFeeAmount: number // % aplicado
  exchangeFeeAmount: number
  advanceFeeAmount: number
  taxAmount: number
  netAmount: number
  netAmountLocalCurrency: number
  isInstant: boolean
  transactions?: ISwapTransaction[]
}

export interface IConfigParameters {
  id: number
  name: string
  value: string
  enabled: boolean
}

export interface IUser {
  cbu: string
  blockChainAddress: {
    ethereum?: string
    polygon?: string
    algorand?: string
  }
}

export interface ITokenizationCompletedForSwap {
  id: number
  currency: CryptoCurrencySymbol
  effectiveDate: string
  expirationDate: string
  destokenizationDate: string
  amount: number
  network: ENetworks
}

export interface IAmountByNetwork {
  network: ENetworks
  amount: number
}

export interface IAuthTokens {
  accessToken: string
}

export interface IExtendedRequest extends Request {
  authToken?: string | (() => string)
  publicToken?: string | (() => string)
}

export interface ISwapResponse {
  statusCode: number
  errorMessage?: string
}

export enum ETokenizationState {
  COMPLETED = 'Completed',
  IN_PROGRESS = 'In progress'
}

export interface IUserTransactionsParams{
  dateStart?: string,
  dateEnd?: string,
  transactionType?: number,
  crypto?: CurrencySymbol
}

export interface IUserTransactionsResponse{
  result: number
}

export enum EMovementsFilter {
  'ALL' = 1,
  'TODAY' = 2,
  'DATE_RANGE' = 3
}

export type TTooltipArrow =
  | 'bottom-center'
  | 'bottom-left'
  | 'bottom-right'
  | 'top-center'
  | 'left'
  | 'right'
  | 'none';

export type TTooltipTheme = 'light' | 'dark';
export type TTextAlignment =
  | 'text-left'
  | 'text-right'
  | 'text-center'
  | 'text-justify';
