import { useState, ChangeEvent } from 'react';
import Icon from '../Icon'
import Select, { components } from 'react-select';
import { IOptionSelect, IconType, CurrencySymbol } from '../../common/types';
import { formatNumber } from '../../common/utils'
import { useTranslation } from 'react-i18next'
import './CustomReactSelect.css'

const CustomOption = props => {
  const { data, innerRef, innerProps } = props;
  const { t } = useTranslation() 
  return !data.icon ? (
    <div ref={innerRef} {...innerProps} className="flex items-center justify-between px-3 py-2 text-sm text-gray-700  pointer-events-none">
      <span>{t("swap.form.customDefaultOption.token")}</span>
      <span>{t("swap.form.customDefaultOption.balance")}</span>
    </div>
  ) : (
    <components.Option {...props}>
      <div className="flex items-center justify-between text-sm text-gray-500">
        <div className="flex">
          <Icon icon={props.data.icon as IconType} size="sm" cssClass="mr-1" />
          <span>{props.data.value}</span>
        </div>
        {
          props.data.balance &&
          <div>
            {formatNumber(props.data.balance)}
          </div>
        }
      </div>
    </components.Option>
  )
}

interface Props {
  options: IOptionSelect[]
  isSearchable?: boolean
  value?: CurrencySymbol
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void
}

const CustomSelect = ({ options, onChange, isSearchable, value }: Props) => {

  const defaultIconSelected: IconType | null = value ? value.toLocaleLowerCase() as IconType : null
  const [cryptoSelected, setCryptoSelected] = useState<IconType | null>(defaultIconSelected)
  const { t } = useTranslation() 

  const onDropdownChange = (value) => {
    setCryptoSelected(value.icon)
    onChange(value)
  }

  return (
    <div className="relative">
      {cryptoSelected && <Icon icon={cryptoSelected as IconType} size="sm" cssClass="flex absolute z-10 px-2 top-3" />}
      <Select
        placeholder={t("swap.form.placeholderSelect")}
        defaultValue={options.filter(opt => opt.value === value)}
        onChange={onDropdownChange}
        components={{ Option: CustomOption, IndicatorSeparator: () => null }}
        options={options}
        className="text-base text-gray-900 placeholder-gray-900"
        isSearchable={isSearchable ? true : false}
        isOptionDisabled={(option) => option.disabled ? option.disabled : false}
        styles={{
          menu: base => ({ ...base, zIndex: 9999, borderRadius: '0.125rem', marginTop: '0.4rem' }),
          control: style => ({ ...style, height: '2.5rem', borderRadius: '0.125rem', appearance: 'none', '&:hover': { cursor: 'pointer' } }),
          option: (base, { isSelected }) => ({ ...base, backgroundColor: isSelected ? '#F3F4F6' : '#FFFFFF', '&:hover': { backgroundColor: '#F9FAFB', cursor: 'pointer' } })   
        }}
      />
    </div>
  );
};

export default CustomSelect