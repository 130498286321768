import React from "react";
import { useTable, useGlobalFilter, Column, usePagination, useSortBy } from "react-table";
import PagedBar from '../PagingBar/PagedBar'
import FilterBar from '../FilterBar'
import SearchBar from '../SearchBar'
import Icon from '../Icon'
import classNames from 'classnames'
import EmptyState from "../EmptyState"
import { useTranslation } from 'react-i18next'
import { HelpIcon } from "../HelpIcon";

interface TableProps<T extends object> {
  columns: Column<T>[];
  data: T[];
}

export function Table<T extends { id: number }>({
  columns,
  data
}: TableProps<T>): React.ReactElement {
  const {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize }
  } = useTable<T>(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0, pageSize: (Number)(process.env.REACT_APP_DEFAULT_PAGE_SIZE),
        sortBy: [
          {
            id: 'date',
            desc: true
          }
        ]
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const handleFilterInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setGlobalFilter(value);
  }

  const { t } = useTranslation()

  return (
    <div>
      <div className="flex border-b border-gray-200 justify-between px-4 py-3">
        {/* <FilterBar onClick={(value) => setGlobalFilter(value)} /> */}
        <SearchBar placeholder={t("search.placeholder")} onChange={handleFilterInputChange} />
      </div>
      {
        data.length > 0 && <div className="m-w-full overflow-auto">
          <table className="min-w-full w-auto divide-y divide-gray-200 overflow-x-scroll lg:overflow-x-auto" {...getTableProps()}>
            <thead className="bg-gray-50">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))} className={classNames('px-6 py-3 text-left text-xs font-normal text-gray-500 tracking-wider hidden lg:table-cell', { 'hover:text-gray-700': column.canSort })}>
                      <div className="flex">
                        {column.render("Header")}
                        {column.id === '3' && <div className="ml-1 z-10"><HelpIcon
                          tooltipTheme='dark'
                          tooltipArrow='top-center'
                          tooltipText={t("movements.tooltipTextValorization")}
                          tooltipTextAlign="text-center"
                        /></div>
                        }
                        {/* Render the columns filter UI */}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? <Icon icon="arrow_down" size="xs" cssClass="ml-1 " />
                              : <Icon icon="arrow_up" size="xs" cssClass="ml-1" />
                            : ''}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className="flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
                    {row.cells.map((cell, i) => {
                      return (
                        <td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap text-sm text-gray-700  lg:w-auto border border-b lg:border-none w-full block lg:table-cell relative lg:static text-center lg:text-left">
                          <span className="lg:hidden absolute top-4 left-0  px-2 py-1 text-xs font-bold uppercase">{columns[i]['Header']}</span>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <PagedBar
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalResults={data.length}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
          />
        </div>
      }
      {
        data.length === 0 && <EmptyState
          iconName="empty_state_mov.svg"
          title="emptyState.movements.title"
          description="emptyState.movements.description"
        />
      }
    </div>
  );
}
