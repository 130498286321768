import { useQuery } from 'react-query'
import { IFee } from '../common/types'
import { getFees } from '../service/rates'

export const useFees = () => {

  // Query
  return useQuery<IFee[] | undefined>(
    'fees',
    getFees
  )
}