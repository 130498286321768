import { useQuery } from 'react-query'
import { ICurrencyRate } from '../common/types'
import { getRates } from '../service/rates'

export const useRates = () => {

  // Interval time for refetch the data
  const intervalMs = +process.env.REACT_APP_REFETCH_INTERVAL_FOR_RATES!

  // Query
  return useQuery<ICurrencyRate[] | undefined>(
    'rates',
    getRates,
    {
      refetchInterval: intervalMs,
    }
  )
}