export const TRANSLATIONS_EN = {
  header: {
    links: {
      myRequests: 'My request',
      wallet: 'Wallet',
      tokenization: 'Tokenization',
      accountProfile: 'My account'
    }
  },
  rates: {
    title: 'Rates',
    errorLoadingMessage: 'Error found loading rates ...'
  },
  portfolio: {
    title: 'Wallet',
    description: 'Total portfolio valued in '
  },
  wallet: {
    cardTitle: 'Wallet',
    cardDescription: 'Balance',
    columns: {
      active: 'Active',
      tokenPrice: 'Token Price',
      balance: 'Balance'
    }
  },
  swap: {
    initial: {
      cardTitle: 'Swap tokens',
      cardDescription: 'Convet token'
    },
    preview: {
      cardTitle: 'Swap tokens',
      cardDescription: 'Transaction preview'
    },
    transaction: {
      cardTitle: 'Transaction sent successfully',
      cardDescription: 'Sent email'
    },
    form: {

    }
  },
  movements: {
    cardTitle: 'Movements',
    cardDescription: 'All movements in your wallet',
  }
}
