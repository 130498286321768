import classNames from 'classnames';
import React from 'react'
import { IconType } from '../common/types'

type IconSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'none';

const ICONS = {
  soya: require('../assets/icons/soya.svg').default,
  cora: require('../assets/icons/cora.svg').default,
  whea: require('../assets/icons/whea.svg').default,
  usd: require('../assets/icons/usdc.svg').default,
  ars: require('../assets/icons/ars.svg').default,
  single_angle_down: require('../assets/icons/single_angle_down.svg').default,
  arrow_right: require('../assets/icons/arrow_right.svg').default,
  arrow_right_white: require('../assets/icons/arrow_right_white.svg').default,
  arrow_left: require('../assets/icons/arrow_left.svg').default,
  arrow_up: require('../assets/icons/arrow_up.svg').default,
  search: require('../assets/icons/search.svg').default,
  detail: require('../assets/icons/detail.svg').default,
  calendar: require('../assets/icons/calendar.svg').default,
  hand_up: require('../assets/icons/hand_up.svg').default,
  hashtag: require('../assets/icons/hashtag.svg').default,
  greater_than: require('../assets/icons/greater_than.svg').default,
  usdc: require('../assets/icons/usdc.svg').default,
  bitcoin: require('../assets/icons/bitcoin.svg').default,
  arrow_down: require('../assets/icons/arrow_down.svg').default,
  success: require('../assets/icons/success.svg').default,
}

const ICON_SIZES_MAP = {
  xs: 'h-3',
  sm: 'h-4',
  md: 'h-8',
  lg: 'h-16',
  xl: 'h-24',
  none: ''
}
interface Props {
  icon: IconType;
  size: IconSize;
  cssClass?: string;
}

const Icon = ({icon, size, cssClass}: Props) => {
  return (
    <>
      <img src={ICONS[icon]} alt={`Icon ${icon}`} className={classNames(`${ICON_SIZES_MAP[size]}`, {[`${cssClass}`]: cssClass })} />
    </>
  )
}

export default Icon
