import { http } from '../http-common';
import { IUser, IApiResponse, IUserTransactionsResponse, IUserTransactionsParams } from '../common/types';

export const getUser = (): Promise<IUser | undefined> => {
	return http.get<IApiResponse<IUser>>('/user').then((resp)  => {
      const dataResult: IUser | undefined = resp.data.data
      return dataResult
    });
}

export const sendMailToUserWithTransactions = (body: IUserTransactionsParams): Promise<IUserTransactionsResponse | undefined> => {
  return http.post<IApiResponse<IUserTransactionsResponse>>('/user/send_transactions', body).then((resp) => {
    const dataResult: IUserTransactionsResponse | undefined = resp.data.data 
    return dataResult
  })
}