import classNames from 'classnames';
import { ChangeEvent, useState, useEffect } from 'react'
import { useForm, Controller } from "react-hook-form"
import { CurrencySymbol, IOptionSelect, ISwapForm, IBalance, IRadioButton, IFee } from '../../common/types';
import CustomSelect from './CustomSelect'
import Icon from '../Icon'
import styles from '../Button/Button.module.css'
import { totalAmountToReceive, formatNumber } from '../../common/utils';
import { useTranslation } from "react-i18next"

interface Props {
  onSubmit: (data: any) => void
  initialValues: ISwapForm
  previewButtonDisabled: boolean
  balances: IBalance[]
  fees: IFee[]
}

const SwapForm = ({ onSubmit, initialValues, previewButtonDisabled, balances, fees }: Props) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isSubmitted }
  } = useForm({
    mode: "onSubmit",
    defaultValues: initialValues
  });

  const [showPreviewButton, setShowPreviewButton] = useState<Boolean>(!previewButtonDisabled)
  const [balance, setBalance] = useState<number | null | undefined>(null)
  const [cryptoToSellSelected, setCryptoToSellSelected] = useState<string | null>(null)
  const [cryptoToReceiveSelected, setCryptoToReceiveSelected] = useState<string | null>(null)
  const localCurrency = process.env.REACT_APP_LOCAL_CURRENCY!
  const [tokenPriceToSell, setTokenPriceToSell] = useState<number>(0)
  const [depositTypeValue, setDepositTypeValue] = useState<number>(initialValues.depositType)
  const { t } = useTranslation()

  useEffect(() => {
    if (initialValues.from) {
      const getBalance: IBalance = balances.find(b => b.currencySymbol === initialValues.from)!
      setCryptoToSellSelected(initialValues.from)
      setCryptoToReceiveSelected(initialValues.to)
      setBalance(getBalance.balance)
      setTokenPriceToSell(getBalance?.price!)
    }
  }, [initialValues, balances])

  const changeCurrencyToSell = (data) => {
    const { value } = data
    const getBalance = balances.find(b => b.currencySymbol === value)!
    setCryptoToSellSelected(value)
    setBalance(getBalance.balance)
    setTokenPriceToSell(getBalance.price!)
    setValue('from', value as CurrencySymbol)
    if (getValues('amountToSell') !== undefined && +getBalance.balance >= +getValues('amountToSell')!)
      clearErrors("amountToSell")

    if (isSubmitted && getValues('amountToSell')?.toString() !== '' && getValues('amountToSell') !== undefined && +getBalance.balance < +getValues('amountToSell')!)
      setError("amountToSell", { type: 'manual', message: t("swap.form.amountToSellErrorMessage") })

    if (value !== "" && getValues('amountToSell') !== undefined && +getValues('amountToSell')! > 0 && getValues('to') !== '') {
      setValue('amountToReceive', formatNumber(totalAmountToReceive(fees, getBalance.price!, getValues('amountToSell')!, depositTypeValue === 0), false))
      setShowPreviewButton(true)
    } else {
      setValue('amountToReceive', '')
      setShowPreviewButton(false)
    }
  }

  const changeAmountToSell = (e: ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value)
    if (value > 0 && getValues('from') !== '' && getValues('to') !== '') {
      setValue('amountToReceive', formatNumber(totalAmountToReceive(fees, tokenPriceToSell, value, depositTypeValue === 0), false))
      setShowPreviewButton(true)
    } else {
      setValue('amountToReceive', '')
      setShowPreviewButton(false)
    }
    const getBalance: IBalance | undefined = getValues('from') ? balances.find(b => b.currencySymbol === getValues('from'))! : undefined

    if (getBalance && +value > 0 && +getBalance.balance >= +value)
      clearErrors("amountToSell")

    if (isSubmitted && getBalance && +value > 0 && +getBalance.balance < value) 
      setError("amountToSell", { type: 'submit', message: t("swap.form.amountToSellErrorMessage") })

  }

  const changeCurrencyToReceive = (data) => {
    const { value } = data
    setValue('to', value as CurrencySymbol)
    setCryptoToReceiveSelected(value)
    if (value !== '' && getValues('from') !== '' && getValues('amountToSell') !== undefined && getValues('amountToSell')! > 0) {
      setValue('amountToReceive', formatNumber(totalAmountToReceive(fees, tokenPriceToSell, getValues('amountToSell')!, depositTypeValue === 0), false))
      setShowPreviewButton(true)
    } else {
      setValue('amountToReceive', '')
      setShowPreviewButton(false)
    }
  }

  const changeDepositType = (value) => {
    if (value > -1 && getValues('from') !== '' && getValues('to') !== '' && getValues('amountToSell') !== undefined && getValues('amountToSell')! > 0) {
      setValue('amountToReceive', formatNumber(totalAmountToReceive(fees, tokenPriceToSell, getValues('amountToSell')!, value === 0), false))
      setShowPreviewButton(true)
    } else {
      setValue('amountToReceive', '')
      setShowPreviewButton(false)
    }
  }

  const currencyToSellOptions: IOptionSelect[] = [
    { value: null, label: null, icon: null, disabled: false },
  ];

  balances.forEach((balance) => {
    const newOption = { value: balance.currencySymbol, label: balance.currencySymbol, icon: balance.icon, balance: balance.balance }
    currencyToSellOptions.push(newOption)
  })

  const currencyToReceiveOptions: IOptionSelect[] = [
    { value: "ARS", label: "ARS", icon: "ars", disabled: false },
    // { value: "USDC", label: "USDC", icon: "usdc", disabled: true },
    // { value: "BITCOIN", label: "BITCOIN", icon: "bitcoin", disabled: true }
  ];

  const radioButtons: IRadioButton[] = [
    { id: "field-instant", value: "0", label: t("swap.form.instantRadioButton") },
    { id: "field-seven-days", value: "7", label: t("swap.form.inSevenDaysRadioButton") }
  ]

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 p-4 gap-6">
        <div className="flex items-end" >
          <div className="w-full ">
            <label className="block mb-1 text-gray-500 text-sm">{t("swap.form.inputSellLabel")}:</label>
            <div className="relative inline-block w-full">
              <Controller
                control={control}
                name="from"
                render={() => (
                  <CustomSelect
                    value={initialValues.from as CurrencySymbol}
                    options={currencyToSellOptions}
                    onChange={changeCurrencyToSell}
                  />
                )}
              />
            </div>
            {
              balance &&
              <span className="flex items-center font-normal tracking-wide text-gray-700 text-xs mt-1 ">
                {t("swap.form.balance")}: {formatNumber(balance)} {cryptoToSellSelected}
              </span>
            }
          </div >
          <div className="ml-2 w-full">
            <input
              className="w-full h-10 px-4 text-base text-gray-900 placeholder-gray-400 border rounded-sm focus:shadow-outline text-right"
              type="number"
              lang="es"
              step=".0001"
              max="99999"
              onWheel={event => {
                event.currentTarget.blur()
              }}
              placeholder="0"
              {...register('amountToSell', { required: true, validate: (value) => +value! > 0 && +value! < +balance!, onChange: changeAmountToSell })}
            />
            {
              errors.amountToSell &&
              <span className="flex justify-end font-normal tracking-wide text-red-500 text-xs mt-1 ">
                {errors.amountToSell.message ? errors.amountToSell.message : t("swap.form.amountToSellErrorMessage")}
              </span>
            }
            {
              !errors.amountToSell && cryptoToSellSelected &&
              <span className="flex justify-end font-normal tracking-wide text-gray-700 text-xs mt-1 ">
                1 {cryptoToSellSelected} = {localCurrency} {formatNumber(tokenPriceToSell, false)}
              </span>
            }
          </div>
        </div>
        <div>
          <div className="flex items-end flex-row">
            <div className="w-full text-gray-500">
              <label className="block mb-1 text-sm">{t("swap.form.inputReceiveLabel")}:</label>
              <div className="relative inline-block w-full">
                <Controller
                  control={control}
                  name="to"
                  render={() => (
                    <CustomSelect
                      options={currencyToReceiveOptions}
                      value={initialValues.to as CurrencySymbol}
                      onChange={changeCurrencyToReceive}
                    />
                  )}
                />
              </div>
            </div >
            <div className="ml-2 w-full mt-1">
              <input
                className="w-full h-10 px-4 text-base text-gray-700 placeholder-gray-600 border rounded-sm focus:shadow-outline text-right"
                disabled
                {...register('amountToReceive')}
              />
              <span className="hidden items-center font-medium tracking-wide text-gray-500 text-xs mt-1  justify-end">
                {cryptoToReceiveSelected}
              </span>
            </div>
          </div>
          {
            showPreviewButton &&
            <span className="flex flex-row items-center font-normal tracking-wide text-gray-700 text-xs mt-1 ">
              {t("swap.form.feesIncludedMessage")}
            </span>
          }
        </div>
        <div className="flex items-center">
          <span className="text-gray-500 text-sm">{t("swap.form.deposit")}:</span>
          {radioButtons.map(radio => (
            <div key={radio.id} className="ml-6">
              <label htmlFor={radio.id} className="flex items-center text-gray-700 text-base">
                <input
                  {...register('depositType', { required: true })}
                  id={radio.id}
                  type="radio"
                  name="depositType"
                  value={radio.value}
                  checked={depositTypeValue.toString() === radio.value}
                  onClick={() => setDepositTypeValue(Number(radio.value))}
                  onChange={() => changeDepositType(Number(radio.value))}
                  className="mr-1"
                />
                {radio.label}
              </label>
            </div>
          ))}
        </div>
        <div className="flex flex-1">
          <button className={classNames(`${styles.btn} w-full`, { [`btn-outline ${styles.disabled}`]: !(showPreviewButton) }, { [styles.btnPrimary]: showPreviewButton })} type="submit">
            <div className="flex items-center justify-center">
              <span className={`mr-2 text-md ${(showPreviewButton) ? 'text-white' : 'text-gray-700'}`}>{t("swap.form.previewButton")}</span>
              <Icon icon={!showPreviewButton ? 'arrow_right' : 'arrow_right_white'} size="xs" />
            </div>
          </button>
        </div>
      </div>
    </form >
  )
}

export default SwapForm
