import React, { useEffect } from 'react'
import { Link, useParams } from "react-router-dom"
import Icon from '../Icon'
import PulseLoader from "react-spinners/PulseLoader"
import { useMovementDetail } from '../../customHooks/useMovementDetail'
import { movementTypes } from '../../common/const'
import { formatNumber, formatUserCbu } from '../../common/utils'
import { ESwapState } from '../../common/types'
import { useTranslation } from 'react-i18next'

const MovementDetail = () => {
  const { t } = useTranslation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { url, id } = useParams()

  // Query
  const { isLoading, isError, data: movementDetail } = useMovementDetail(url!, +id!)

  if (isLoading) {
    return (
      <div className="flex flex-row justify-center items-center mt-48 text-sm ">
        <PulseLoader color="#57D972" size={12} margin={2} />
      </div>
    )
  }

  if (isError) {
    return (
      <div className="flex flex-row justify-center items-center mt-48 text-sm">
        {t("movements.movementDetail.errorLoadingMessage")}
      </div>
    )
  }

  const SwapDetail = () => {
    return (
      <>
        <div className="flex flex-col mt-6">
          <div className="font-normal" style={{ fontSize: '36px', lineHeight: '44px' }}>
            {t("movements.movementDetail.tokensConvert")}
          </div>
          <span className="text-base mt-1 text-gray-500">{t("movements.movementDetail.transactionInformation")}</span>
        </div>

        <div className="w-full lg:w-4/5">
          <div className="flex flex-col lg:flex-row py-8 border-b justify-between font-normal">
            <div className="flex items-center pb-4 lg:pb-0">
              <Icon icon="hand_up" size="none" cssClass="p-2 bg-primary-50 rounded-lg" />
              <div className="flex flex-col ml-2">
                <span className="text-gray-700 text-sm">{t("movements.movementDetail.state")}</span>
                <span className="text-gray-900 text-base">{ESwapState.DONE === +movementDetail!.state ? t("movements.states.completed") : t("movements.states.inProgress")}</span>
              </div>
            </div>
            <div className="flex items-center pb-4 lg:pb-0">
              <Icon icon="hashtag" size="none" cssClass="p-2 bg-primary-50 rounded-lg" />
              <div className="flex flex-col ml-2">
                <span className="text-gray-700 text-sm">{t("movements.movementDetail.transaction")}</span>
                <span className="text-gray-900 text-base">{movementDetail?.transaction}</span>
              </div>
            </div>
            <div className="flex items-center pb-4 lg:pb-0">
              <Icon icon="calendar" size="none" cssClass="p-2 bg-primary-50 rounded-lg" />
              <div className="flex flex-col text-xs ml-2">
                <span className="text-gray-700 text-sm">{t("movements.movementDetail.date")}</span>
                <span className="text-gray-900 text-base">{movementDetail?.date}</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-10 lg:mt-7 font-normal">
            <p className="text-gray-900" style={{ fontSize: '20px', lineHeight: '30px' }}>{t("movements.movementDetail.transactionDetail")}</p>
            <div className="flex flex-col p-5 border rounded-sm mt-2 text-sm lg:text-base">
              <div className="flex items-center justify-between mb-4">
                <span className="text-gray-700">{t("movements.movementDetail.tokensToSell")}</span>
                <div className="flex items-center text-gray-900">
                  <span className="mr-2">{formatNumber(movementDetail?.cryptoAmount!)}</span>
                  <Icon icon="soya" size="xs" />
                  <span className="ml-2">{movementDetail?.cryptoCurrency}</span>
                </div>
              </div>
              <div className="flex items-center justify-between mb-4">
                <span className="text-gray-700">{t("movements.movementDetail.conversion")}</span>
                <div className="flex items-center">
                  <span>1 {movementDetail?.cryptoCurrency} / {formatNumber(movementDetail?.currencyToUSDRate! * movementDetail?.usdToLocalCurrencyRate!, false)} {movementDetail?.localCurrency}</span>
                </div>
              </div>
              <div className="flex items-center justify-between mb-4">
                <span className="text-gray-700">{t("movements.movementDetail.nominalValueToReceive")}</span>
                <div className="flex items-center">
                  <span className="text-gray-900">{formatNumber(movementDetail?.cryptoAmount! * movementDetail?.currencyToUSDRate! * movementDetail?.usdToLocalCurrencyRate!, false)}</span>
                  <Icon icon="ars" size="sm" cssClass="ml-2 mr-1" />
                  <span>{movementDetail?.localCurrency}</span>
                </div>
              </div>
              <div className="flex items-center justify-between mb-4">
                <span className="text-gray-700">{t("movements.movementDetail.agrotokenFee")} ({movementDetail?.agrotokenFee!}%) = {formatNumber(movementDetail?.agrotokenFeeAmount!)} {movementDetail?.cryptoCurrency}</span>
                <div className="flex items-center">
                  <span className="text-gray-900">-{movementDetail?.localCurrency} {formatNumber(movementDetail?.agrotokenFeeAmount! * movementDetail?.currencyToUSDRate! * movementDetail?.usdToLocalCurrencyRate!, false)}</span>
                </div>
              </div>
              <div className="flex items-center justify-between mb-4">
                <span className="text-gray-700">{t("movements.movementDetail.exchangeFee")} ({movementDetail?.exchangeFee!}%) = {formatNumber(movementDetail?.exchangeFeeAmount!)} {movementDetail?.cryptoCurrency}</span>
                <div className="flex items-center">
                  <span className="text-gray-900">-{movementDetail?.localCurrency} {formatNumber(movementDetail?.exchangeFeeAmount! * movementDetail?.currencyToUSDRate! * movementDetail?.usdToLocalCurrencyRate!, false)}</span>
                </div>
              </div>
              {
                movementDetail?.isInstant &&
                <div className="flex items-center justify-between mb-4">
                  <span className="text-gray-700">{t("movements.movementDetail.advanceFee")} = {formatNumber(movementDetail?.advanceFeeAmount!)} {movementDetail.cryptoCurrency}</span>
                  <div className="flex items-center">
                    <span className="text-gray-900">-{movementDetail?.localCurrency} {formatNumber(movementDetail?.advanceFeeAmount! * movementDetail?.currencyToUSDRate! * movementDetail?.usdToLocalCurrencyRate!, false)}</span>
                  </div>
                </div>
              }
              <div className="flex items-center justify-between mb-4">
                <span className="text-gray-700">{t("movements.movementDetail.taxes")} ({movementDetail?.tax}%) = {formatNumber(movementDetail?.taxAmount!)} {movementDetail?.cryptoCurrency}</span>
                <div className="flex items-center">
                  <span>-{movementDetail?.localCurrency} {formatNumber(+movementDetail?.taxAmount! * +movementDetail?.currencyToUSDRate! * +movementDetail?.usdToLocalCurrencyRate!, false)}</span>
                </div>
              </div>
              <div className="border-t border-gray-200 mt-2">
                <div className="pt-4 pb-2">
                  <div className="flex items-center justify-between text-base mb-4">
                    <span className="text-gray-700">{t("movements.movementDetail.toReceive")}</span>
                    <div className="flex items-center">
                      <span>{formatNumber(movementDetail?.localCurrencyAmount!, false)}</span>
                      <Icon icon="ars" size="sm" cssClass="ml-2 mr-1" />
                      <span>{movementDetail?.localCurrency}</span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between text-sm mb-4">
                    <span className="text-gray-700" >{`${ESwapState.DONE === +movementDetail!.state ?  t("movements.movementDetail.depositedIn") : t("movements.movementDetail.willBeDepositedIn")}`}</span>
                    <div className="flex items-center">
                      <span>CBU {formatUserCbu(movementDetail!.cbu!)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const isSwapDetail = movementDetail && movementDetail.transactionType === movementTypes.swap.transactionType

  return (
    <div className="container mt-10 px-4 lg:px-0 max-w-5xl">
      <div className="flex text-sm items-center">
        <Link to="/" className="hover:text-primary text-gray-500">{t("movements.movementDetail.wallet")}</Link>
        <Icon icon="greater_than" size="none" cssClass="px-3 h-2" />
        <span className="text-gray-700">{t("movements.movementDetail.tokensConvert")}</span>
      </div>
      {
        isSwapDetail && <SwapDetail />
      }
      <div className="flex text-sm my-8">
        <p>{t("movements.movementDetail.issueTransactionMessage")}</p>
        <a href="mailto:hello@agrotoken.io" className="text-primary cursor-pointer ml-1">{t("movements.movementDetail.sendAnEmail")}</a>
      </div>
    </div >
  )
}

export default MovementDetail
