import type { IBalance, IBalanceHeaderColumn } from '../../common/types'
import BalanceHeader from './BalanceHeader'
import BalanceList from './BalanceList'
import Card from '../Card'
import EmptyState from "../EmptyState"

const balanceColumns: IBalanceHeaderColumn[] = [
  { translationKey: 'active', align: 'left' },
  { translationKey: 'tokenPrice', align: 'center' },
  { translationKey: 'balance', align: 'right' }
]
interface Props {
  balances: IBalance[]
}

const Balances = ({ balances }: Props) => {
  return (
    <Card componentTranslationKey="wallet">
      {
        balances.length > 0 &&
        <div>
          <BalanceHeader columns={balanceColumns} />
          <BalanceList balances={balances} />
        </div>
      }
      {
        balances.length === 0 && <EmptyState
          iconName="empty_state_wallet.svg"
          title="emptyState.balances.title"
          description="emptyState.balances.description"
        />
      }
    </Card >
  )
}

export default Balances
