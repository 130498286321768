import React from 'react'
import { useTranslation } from 'react-i18next'
import Icon from './Icon'

interface Props {
  componentTranslationKey: string
  children?: React.ReactNode | undefined | null
  titleWithIcon?: boolean
}

const Card = ({ componentTranslationKey, children, titleWithIcon }: Props) => {
  const { t } = useTranslation()
  const title: string = componentTranslationKey + '.cardTitle'
  const description: string = componentTranslationKey + '.cardDescription'
  return (
    <div className="card border rounded-sm border-gray-200 shadow-md">
      <div className="card-header border-b border-gray-200 p-4">
        <div className="flex flex-col font-normal">
          <div className="text-gray-900 text-lg flex items-center">
            {titleWithIcon && <Icon icon="success" size="none" cssClass="mr-2 w-5 h-5" />}
            {t(title)}
          </div>
          <p className="text-gray-500 mt-1 text-sm">{t(description)} </p>
        </div>
      </div>
      <div className="card-body">{children}</div>
    </div>
  )
}

export default Card
