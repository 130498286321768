import axios from 'axios'
import { StatusCodes } from 'http-status-codes'

import { ACCESS_TOKEN_KEY } from './common/const'
import { IApiResponse, IAuthTokens } from './common/types'

// Default axios instance config
// withCredentials = true allows cookies
axios.defaults.withCredentials = true

// Base axios Instance
export const http = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-type': 'application/json',
  }
})

export class AuthManager {
  static getLocalAccessToken = () => {
    return window.sessionStorage.getItem(ACCESS_TOKEN_KEY)
  }

  static clearLocalAccessToken = () => {
    window.sessionStorage.clear()
  }

  static setLocalAccessToken = (accessToken: string) => {
    window.sessionStorage.clear()
    window.sessionStorage.setItem(ACCESS_TOKEN_KEY, accessToken)
    window.sessionStorage.setItem('_retry', '')
  }

  static login = async (): Promise<IAuthTokens | void> => {
    return http
      .get<IApiResponse<IAuthTokens>>('/auth/login', {
        withCredentials: true,
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0'
        }
      })
      .then(
        (resp) => {
          if (resp.data.responseCode === StatusCodes.OK) {
            return resp.data.data
          } else if (resp.data.responseCode === StatusCodes.UNAUTHORIZED) {
            AuthManager.clearLocalAccessToken()
            window.location.href = process.env.REACT_APP_LOGIN_URL!
          }
        },
        (err) => {
          AuthManager.clearLocalAccessToken()
          window.location.href = process.env.REACT_APP_LOGIN_URL!
        }
      )
  }
}

http.interceptors.request.use(
  (config) => {
    const token = AuthManager.getLocalAccessToken()
    if (token) {
      config.headers!['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    const originalConfig = err.config
    if (err.response) {
      if (err.response.status === StatusCodes.UNAUTHORIZED && !window.sessionStorage.getItem('_retry')) {
        originalConfig._retry = true
        window.sessionStorage.setItem('_retry', '1')

        try {
          const rs: any = await AuthManager.login()
          AuthManager.setLocalAccessToken(rs.accessToken)

          return http(originalConfig)
        } catch (_error: any) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data)
          }
          return Promise.reject(_error)
        }
      }

      if (err.response.status === StatusCodes.FORBIDDEN && err.response.data) {
        return Promise.reject(err.response.data)
      }
    }
    return Promise.reject(err)
  }
)
