import React from 'react'
import { IBalance } from '../../common/types'
import BalanceItem from './BalanceItem'

interface Props {
  balances: IBalance[]
}

const BalanceList = ({ balances }: Props) => {
  return (
    <div>
      {
        balances.map((balance: IBalance) => <BalanceItem key={balance.currencySymbol} balance={balance} />)
      }
    </div>
  )
}

export default BalanceList
