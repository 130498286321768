import React from 'react'
import Icon from '../Icon'
import { IBalance } from '../../common/types'
import { formatNumber } from '../../common/utils'

interface Props {
  balance: IBalance
}

const BalanceItem = ({ balance }: Props) => {

  const totalBalance = (balance.price! * balance.balance)

  return (
    <div className="flex flex-row py-3 px-4 border-gray-200 border-b">
      <div className="flex items-center flex-1">
        <Icon icon={balance.icon} size="md" />
        <div className="ml-2 flex flex-col items-start text-sm">
          <p className="font-normal text-gray-900">{balance.currencyDescription}</p>
          <p className="text-gray-500">{balance.currencySymbol}</p>
        </div>
      </div>
      <div className="flex items-center flex-1 text-sm justify-center">
        <p className="text-gray-500">{`${balance.priceCurrency} ${formatNumber(balance.price!, false)}`}</p>
      </div>
      <div className="flex flex-col items-end flex-1 justify-end">
        <p className="text-sm text-gray-900">{`${formatNumber(balance.balance)} ${balance.currencySymbol}`}</p>
        <p className="text-xs text-gray-500">~{`${formatNumber(totalBalance, false)} ${balance.priceCurrency}`}</p>
      </div>
    </div>
  )
}

export default BalanceItem
