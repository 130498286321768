import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home';
import Movement from './Movements/MovementDetail';

const Content = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path="movements/:url/:id" element={<Movement />} />
      </Routes>
    </BrowserRouter>
  )

}

export default Content
