import React from 'react'
import { ISwapForm, IUser } from '../../common/types'
import SwapContent from './SwapContent'

interface Props {
  data: ISwapForm
  onClick: () => void,
  tokenPrice: number,
  feeAndTaxesDetails: any
  user: IUser
}

const SwapTransactionDetail = ({ data, tokenPrice, feeAndTaxesDetails, user, onClick }: Props) => {
  return (
    <SwapContent
      data={data}
      feeAndTaxesDetails={feeAndTaxesDetails}
      tokenPrice={tokenPrice}
      user={user}
      isPreview={false}
      handleGoToStart={onClick}
    />
  )
}

export default SwapTransactionDetail
