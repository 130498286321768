import React from 'react'
import { IBalanceHeaderColumn } from '../../common/types'
import { useTranslation } from "react-i18next"

interface Props {
  columns: IBalanceHeaderColumn[]
}

const justify = {
  'left': 'justify-start',
  'center': 'justify-center',
  'right': 'justify-end'
}

const BalanceHeader = ({ columns }: Props) => {
  const { t } = useTranslation()
  return (
    <div className="flex bg-gray-100 px-4 py-3  border-b border-gray-200 text-gray-500" style={{ fontSize: '12px', lineHeight: '18px' }}>
      {
        columns.map((col) => {
          return (
            <div key={col.translationKey} className={`flex w-1/3 items-center ${justify[col.align]}`}>
              {t("wallet.columns."+col.translationKey)}
              {
                col.helpDescription && <div className="ml-1 hidden border border-gray-400 rounded-full h-4 w-4 items-center justify-center">
                  <p style={{ fontSize: '10px', lineHeight: '10px' }}>?</p>
                </div>
              }
            </div>
          )
        })
      }
    </div>
  )
}

export default BalanceHeader
