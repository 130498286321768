import Icon from './Icon'
import { ICurrencyRate } from '../common/types'
import PulseLoader from 'react-spinners/PulseLoader'
import { useRates } from '../customHooks/useRates'
import { formatNumber } from '../common/utils'
import { useTranslation } from "react-i18next"

const Rates = () => {
  // Query
  const { isLoading, isError, data: rates } = useRates()
  const { t } = useTranslation()

  if (isLoading) {
    return (
      <div className="flex flex-row justify-end items-center pt-4 pr-4 mb-8 text-sm ">
        <PulseLoader color="#57D972" size={12} margin={2} />
      </div>
    )
  }

  if (isError) {
    return (
      <div className="flex flex-row justify-end items-center pt-4 pr-8 mb-8 text-sm ">
        {t("rates.errorLoadingMessage")}
      </div>
    )
  }

  return (
    <div className="flex flex-col lg:flex-row lg:justify-end lg:items-center mt-20 lg:mt-4 mb-8 text-sm font-normal text-gray-900 px-4 lg:pr-4">
      <p className="mb-4 lg:mb-0">{t("rates.title")}:</p>
      {
        rates?.map(({ currencyFrom, currencyTo, value }: ICurrencyRate) => {
          return (
            <div key={currencyFrom.symbol} className="flex pl-3 items-center py-2 lg:py-0">
              <Icon icon={currencyFrom.icon} size='sm' />
              <p className="ml-2 mt-0.5">{`${currencyFrom.symbol}: ${currencyTo.symbol} ${value ? formatNumber(value, false) : ''}`}</p>
            </div>
          )
        })
      }
    </div>
  )
}

export default Rates
