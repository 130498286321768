import React, { useState, useRef, useEffect } from 'react'
import SwapForm from '../Forms/SwapForm'
import Card from '../Card'
import type { ISwapForm, IBalance, IFee, IFeeExtended, IUser, ISwapParams, CurrencySymbol, ISwapResponse } from '../../common/types'
import SwapPreview from './SwapPreview'
import SwapTransactionDetail from './SwapTransactionDetail'
import { getFeesAndTaxes, unformatString } from '../../common/utils'
import { queryClient } from '../../queryClient'
import { createSwap } from '../../service/swap'
import EmptyState from '../EmptyState'
import { useTranslation } from 'react-i18next'
import { StatusCodes } from 'http-status-codes'
import PulseLoader from 'react-spinners/PulseLoader'

interface Props {
  balances: IBalance[]
  fees: IFee[]
  user: IUser
}

const Swap = ({ balances, fees, user }: Props) => {
  const { t } = useTranslation()

  const initialValues: ISwapForm = {
    from: '',
    to: (process.env.REACT_APP_LOCAL_CURRENCY as CurrencySymbol) || 'ARS',
    amountToSell: undefined,
    amountToReceive: '',
    depositType: 0
  }

  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [showTransactionDetail, setShowTransactionDetail] = useState<boolean>(false)
  const [data, setData] = useState<ISwapForm>(initialValues)
  const [componentSelected, setComponentSelected] = useState<string>('swap.initial')
  const [previewButtonDisabled, setPreviewButtonDisabled] = useState<boolean>(true)
  const [tokenPrice, setTokenPrice] = useState<number>(0)
  const [feeAndTaxesDetail, setFeeAndTaxesDetail] = useState<IFeeExtended[]>([])
  const [error, setError] = useState<boolean>(false)
  const [submitTransaction, setSubmitTransaction] = useState<boolean>(false)

  const getFeeAndTaxesDetail = useRef(() => {})

  getFeeAndTaxesDetail.current = () => {
    const feesAndTaxesResult: IFeeExtended[] = getFeesAndTaxes(fees, data.amountToSell!, tokenPrice, +data.depositType === 0)
    setFeeAndTaxesDetail(feesAndTaxesResult)
  }

  useEffect(() => {
    if (showPreview) getFeeAndTaxesDetail.current()
  }, [showPreview])

  const handleSubmitSwapToken = (data: ISwapForm) => {
    const getBalance: IBalance | undefined = balances.find((b) => b.currencySymbol === data.from)
    if (getBalance) {
      setTokenPrice(getBalance.price!)
    }
    setData(data)
    setComponentSelected('swap.preview')
    setShowPreview(true)
  }

  const toggle = () => {
    setShowPreview(false)
    setComponentSelected('swap.initial')
    setPreviewButtonDisabled(false)
    setError(false)
  }

  const executeTransaction = async (): Promise<ISwapResponse> => {
    setSubmitTransaction(true)
    setError(false)
    //call swap api
    const swapBody: ISwapParams = {
      from: data.from as CurrencySymbol,
      to: data.to as CurrencySymbol,
      amountRequested: +data.amountToSell!,
      isInstant: +data.depositType === 0,
      netAmountLocalCurrency: unformatString(process.env.REACT_APP_DEFAULT_LOCALE_STRING || 'es-AR', data.amountToReceive)
    }
    return await createSwap(swapBody)
      .then(() => {
        setError(false)
        queryClient.refetchQueries()
        setComponentSelected('swap.transaction')
        setShowTransactionDetail(true)
        setShowPreview(false)
        setSubmitTransaction(false)
        return new Promise<ISwapResponse>((resolve) => resolve({ statusCode: StatusCodes.OK }))
      })
      .catch((error) => {
        setError(true)
        setSubmitTransaction(false)
        return new Promise<ISwapResponse>((resolve) => resolve({ statusCode: StatusCodes.BAD_REQUEST }))
      })
  }

  const goToStart = (): void => {
    setData(initialValues)
    setShowTransactionDetail(false)
    toggle()
    setPreviewButtonDisabled(true)
  }

  return (
    <div className="relative">
      <div className="h-full w-full bg-white opacity-90 absolute inset-0 z-20"></div>
      <EmptyState
        iconName="empty_state_disabled.svg"
        title="emptyState.disabledSwap.title"
        description="emptyState.disabledSwap.description"
        className="absolute inset-0 m-auto z-30"
      />
      <Card componentTranslationKey={componentSelected} titleWithIcon={showTransactionDetail}>
        {balances.length > 0 && (
          <>
            {!showPreview && !showTransactionDetail && (
              <SwapForm onSubmit={handleSubmitSwapToken} initialValues={data} previewButtonDisabled={previewButtonDisabled} balances={balances} fees={fees} />
            )}
            {showPreview && (
              <SwapPreview
                data={data}
                //TODO: OnClick disabled para version de Wallet sin Swap
                // onClick={executeTransaction}
                toggle={toggle}
                tokenPrice={tokenPrice}
                feeAndTaxesDetails={feeAndTaxesDetail}
                user={user}
              />
            )}
            {showTransactionDetail && (
              <SwapTransactionDetail data={data} onClick={goToStart} tokenPrice={tokenPrice} feeAndTaxesDetails={feeAndTaxesDetail} user={user} />
            )}
          </>
        )}
        {balances.length === 0 && <EmptyState iconName="empty_state_swap.svg" title="emptyState.swap.title" description="emptyState.swap.description" />}
        {error && (
          <div className="flex flex-row pb-2 w-full justify-center items-center text-center text-red-500 text-sm">
            <span>{t('swap.errorMessage')}</span>
          </div>
        )}
      </Card>
      {submitTransaction && (
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-500 bg-opacity-25 z-40">
          <div className="flex h-full w-full z-50">
            <div className="m-auto">
              <PulseLoader color="#57D972" size={12} margin={2} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Swap
