import { QueryClient} from 'react-query';

const queryClientConfig = {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  }

 export const queryClient = new QueryClient(queryClientConfig);