import { useQuery } from 'react-query'
import { IMovement } from '../common/types'
import { getMovement } from '../service/movements'

export const useMovementDetail = (url: string, id: number) => {

  // Query
  return useQuery<IMovement | undefined>(
    ['movement', url, id],
    getMovement
  )
}