import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  iconName: string
  title: string
  description: string
  className?: string
}

const EmptyState = ({ iconName, title, description, className = '' }: Props) => {
  const { t } = useTranslation()
  const imgUrl = require(`../assets/logos/${iconName}`).default
  return (
    <div className={`flex flex-col items-center font-normal font-base my-10 justify-center ${className}`}>
      <img className="mb-2" src={imgUrl} alt="img-empty-state" />
      <span className="text-gray-900 mb-2">{t(title)}</span>
      <div className="flex  max-w-sm">
        <span className="text-gray-500 text-center">{t(description)}</span>
      </div>
    </div>
  )
}

export default EmptyState
