import { useQuery } from 'react-query'
import { IMovement } from '../common/types'
import { getMovements } from '../service/movements'

export const useMovements = () => {

  // Query
  return useQuery<IMovement[] | undefined>(
    'movements',
    getMovements
  )
}