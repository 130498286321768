import { IFee, IFeeExtended } from './types'
import { cryptos, feeParams, feesAndTaxes, exchangeValues } from './const'

export const formatNumber = (value: number, isCrypto: boolean = true): string => {
  const locale = process.env.REACT_APP_DEFAULT_LOCALE_STRING ? process.env.REACT_APP_DEFAULT_LOCALE_STRING : 'es-AR'
  const decimals = process.env.REACT_APP_MAX_FRACTION_DIGITS ? +process.env.REACT_APP_MAX_FRACTION_DIGITS : 4
  return value.toLocaleString(locale, {
    maximumFractionDigits: isCrypto ? decimals : 2
  })
}

export const totalAmountToReceive = (fees: IFee[], tokenPrice: number, amount: number, isInstant: boolean): number => {
  console.log('totalAmountToReceive',{fees, tokenPrice, amount, isInstant})
  if (fees.length > 0) {
    const feesAndTaxes = getFeesAndTaxes(fees, amount, tokenPrice, isInstant)

    const netAmount = feesAndTaxes.reduce((prevValue, currentValue) => prevValue - currentValue.currencyFromAmount, amount)
    const netAmountLocalCurrency = roundNumber(netAmount * tokenPrice, 2)
    return netAmountLocalCurrency
  }
  return 0
}

export const isCrypto = (balance): boolean => {
  return Object.keys(cryptos).includes(balance.crypto.name)
}

export const isFee = (parameter): boolean => {
  return parameter.enabled && Object.values(feeParams).includes(parameter.name.trim())
}

export const getFeesAndTaxes = (fees: IFee[], amountToSell: number, tokenPrice: number, isInstant: boolean): IFeeExtended[] => {
  if (fees.length > 0) {
    fees.forEach((fee) => {
      const feeName = fee.name
      switch (feeName) {
        case feeParams.agrotokenFee:
          feesAndTaxes.agrotokenFee.value = +fee.value
          break
        case feeParams.tradeFee:
          feesAndTaxes.tradeFee.value = +fee.value
          break
        case feeParams.cashOut:
          feesAndTaxes.cashOutFee.value = +fee.value
          break
        case feeParams.advanceFee:
          feesAndTaxes.advanceFee.value = isInstant ? +fee.value : 0
          break
        case feeParams.tax:
          feesAndTaxes.taxes.value = +fee.value
          break
        default:
          break
      }
    })

    let remainAmount = amountToSell
    const feesAndTaxesResult: IFeeExtended[] = []
    let cashOutValue = 0

    const feesAndTaxesSorted = Object.values(feesAndTaxes).sort((a, b) => a.order - b.order)

    feesAndTaxesSorted.forEach((fee) => {
      const cryptoAmount = roundNumber(remainAmount * fee.value * 0.01)

      fee.currencyFromAmount = cryptoAmount
      fee.currencyToAmount = roundNumber(cryptoAmount * tokenPrice, 2)
      if (fee.name !== feeParams.cashOut) {
        const isAdvanceFee = fee.name === feeParams.advanceFee
        remainAmount -= isAdvanceFee ? cryptoAmount + cashOutValue : cryptoAmount
      } else {
        cashOutValue = cryptoAmount
      }

      feesAndTaxesResult.push(fee)
    })

    const exchangeFees = feesAndTaxesResult.filter((fee) => exchangeValues.includes(fee.name))
    const exchangeFee = (tradeFee: number, cashOutFee: number): number => roundNumber(tradeFee + roundNumber((1 - tradeFee / 100) * cashOutFee))

    const mergeExchangeFees: IFeeExtended = {
      name: feeParams.exchangeFee,
      value: exchangeFee(exchangeFees[0].value, exchangeFees[1].value),
      description: feesAndTaxes.tradeFee.description,
      currencyFromAmount: exchangeFees[0].currencyFromAmount + exchangeFees[1].currencyFromAmount,
      currencyToAmount: roundNumber(exchangeFees[0].currencyToAmount + exchangeFees[1].currencyToAmount, 2),
      order: 2
    }

    const validFeesAndTaxesResult = feesAndTaxesResult.filter((fee) => +fee.value !== 0)
    validFeesAndTaxesResult.splice(1, 2, mergeExchangeFees)

    return validFeesAndTaxesResult
  }
  return []
}

export const formatUserCbu = (cbu: string) => {
  const lastFour = cbu ? cbu.substr(cbu.length - 4) : '0000'
  const cbuMask = '****** ****** ****** '.concat(lastFour)
  return cbuMask
}

export const unformatString = (locale: string, stringNumber: string): number => {
  const parts = (1234.5).toLocaleString(locale).match(/(\D+)/g)!
  let unformatted = stringNumber

  unformatted = unformatted.split(parts[0]).join('')
  unformatted = unformatted.split(parts[1]).join('.')

  return +unformatted
}

export const roundNumber = (value: number, decimals: number = 4): number => {
  const DECIMALS = Math.pow(10, decimals)
  return Math.round(value * DECIMALS) / DECIMALS
}

export const parseDate = (date: string) => {
  const dateSplitted = date.split('/')
  const [day, month, year] = dateSplitted
  return year.concat('-', month, '-', day)
}
