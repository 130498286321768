import React from 'react'
import { formatNumber, formatUserCbu } from '../../common/utils'
import { IconType, ISwapForm, IUser, ISwapResponse } from '../../common/types'
import Icon from '../Icon'
import styles from '../Button/Button.module.css'
import classNames from 'classnames'
import { useTranslation } from "react-i18next"

interface Props {
  data: ISwapForm
  tokenPrice: number
  feeAndTaxesDetails: any
  user: IUser
  isPreview: boolean
  toggle?: () => void
  handleExecuteTransaction?: () => Promise<ISwapResponse>
  handleGoToStart?: () => void
}

const SwapContent = ({ data, tokenPrice, feeAndTaxesDetails, user, isPreview, toggle, handleExecuteTransaction, handleGoToStart }: Props) => {

  
  const { t } = useTranslation()

  return (
    <div className="flex flex-col p-4 text-sm">
      <div className="flex items-center justify-between mb-2 py-2">
        <span className="text-gray-700">{isPreview ? t("swap.content.tokensToSell") : t("swap.content.tokensSold")}</span>
        <div className="flex items-center">
          <span className="mr-2">{formatNumber(data.amountToSell!)}</span>
          <Icon icon={data.from?.toLowerCase() as IconType} size="sm" />
          <span className="ml-2">{data.from}</span>
        </div>
      </div>
      {
        <div className="flex items-center justify-between mb-4">
          <span className="text-gray-700">{t("swap.content.netAmount")}</span>
          <div className="flex items-center">
            <span>{data.to} {formatNumber(data.amountToSell! * tokenPrice, false)}</span>
          </div>
        </div>
      }
      {
        tokenPrice && <div className="flex items-center justify-between mb-4">
          <span className="text-gray-700">{t("swap.content.conversion")}</span>
          <div className="flex items-center">
            <span>1 {data.from} / {formatNumber(tokenPrice, false)} {data.to}</span>
          </div>
        </div>
      }
      {
        feeAndTaxesDetails.map(detail => (
          <div key={detail.name} className="flex items-center justify-between mb-4">
            <span className="text-gray-700">{t("swap.content." + detail.name)} ({detail.value}%) = {formatNumber(detail.currencyFromAmount)} {data.from}</span>
            <div className="flex items-center">
              <span>-{data.to} {formatNumber(detail.currencyToAmount, false)}</span>
            </div>
          </div>
        ))
      }
      <div className="border-t border-gray-200 mt-2">
        <div className="pt-4 pb-2">
          <div className="flex items-center justify-between mb-2" style={{ fontSize: '18px', lineHeight: '28px' }}>
            <span className="text-gray-700">{isPreview ? t("swap.content.toReceive") : t("swap.content.totalToReceive")}</span>
            <div className="flex items-center">
              <span>{data.amountToReceive}</span>
              <Icon icon={data.to?.toLowerCase() as IconType} size="sm" cssClass="ml-2 mr-1" />
              <span>{data.to}</span>
            </div>
          </div>
          <div className="flex items-center justify-between text-sm mb-4">
            <span className="text-gray-700">{isPreview ? t("swap.content.willBeDeposited") : t("swap.content.willReceiveIn")}</span>
            <div className="flex items-center">
              <span>{t("swap.content.cbu")}{formatUserCbu(user.cbu)}</span>
            </div>
          </div>
        </div>
      </div>
      {
        isPreview && <div>
          <button className={`${styles.btn} ${styles.btnPrimary} w-full`} onClick={handleExecuteTransaction}>
            <div className="flex items-center justify-center">
              <span className="mr-2 text-md">{t("swap.content.executeTransaction")}</span>
              <Icon icon="arrow_right_white" size="xs" />
            </div>
          </button>
          <button className={classNames(`${styles.btn} w-full mt-2 bg-gray-50 hover:bg-gray-100 hover:text-gray-700`)} onClick={toggle}>
            <div className="flex items-center justify-center">
              <span className="mr-2 text-gray-500">{t("swap.content.cancel")}</span>
            </div>
          </button>
        </div>
      }
      {
        !isPreview &&
        <div>
          <button className={`${styles.btn} w-full bg-gray-50 hover:bg-gray-100 hover:text-gray-700`} onClick={handleGoToStart}>
            <div className="flex items-center justify-center">
              <span className="mr-2 text-gray-500">{t("swap.content.goToStart")}</span>
            </div>
          </button>
        </div>
      }
    </div>
  )
}

export default SwapContent
