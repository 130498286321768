import React, { FC, HTMLAttributes } from 'react';
import { TTooltipTheme, TTooltipArrow } from '../../common/types';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  theme: TTooltipTheme; 
  text: string;
  arrow: TTooltipArrow;
  supportText?: string;
  className?: string;
}

export const Tooltip: FC<Props> = ({
  theme,
  text,
  supportText,
  arrow = 'none',
  className = '',
}) => {
  return (
    <div className={`absolute inline-block w-max max-w-xs ${className}`}>
      <div className="relative">
        <div
          className={`rounded-md shadow-lg ${
            supportText ? 'p-3' : 'px-3 py-2'
          } ${theme === 'light' ? 'bg-white' : 'bg-gray-900'}`}
        >
          <p
            className={`text-xs font-medium ${
              theme === 'light' ? 'text-gray-700' : 'text-white'
            }`}
          >
            {text}
          </p>
          {supportText && (
            <p
              className={`text-xs font-normal mt-1 ${
                theme === 'light' ? 'text-gray-500' : 'text-white'
              }`}
            >
              {supportText}
            </p>
          )}
        </div>        
      </div>
    </div>
  );
};