interface Props {
  state: string,
  style?: string
}

const MovementState = ({ state, style }: Props) => {
  return (
    <div className={`px-3 py-1 ${style}`}>
      {state}
    </div>
  )
}

export default MovementState
