const DEFAULT_MAX_PAGES: number = Number(process.env.REACT_APP_DEFAULT_MAX_PAGES);

export const calculateStartingResultNumber = (pageIndex: number, pageSize: number) => {
	return (pageIndex - 1) * pageSize + 1;
};

export const calculateEndingResultNumber = (pageIndex: number, pageSize: number, totalResults: number) => {
	const endingResultNumber = pageIndex * pageSize;
	return endingResultNumber > totalResults ? totalResults : endingResultNumber;
};

const getPageRange = (startingPageNumber: number, maxPages: number): number[] => {
	let pageNumber: number = startingPageNumber;
	let iterator: number = 1;
	const result: number[] = [];

	while (iterator <= maxPages) {
		result.push(pageNumber);

		pageNumber = pageNumber + 1;
		iterator = iterator + 1;
	}

	return result;
};

export const calculatePagerMetadata = (
	canPreviousPage: boolean,
	canNextPage: boolean,
	pageIndex: number,
	pageCount: number,
	maxPages: number = DEFAULT_MAX_PAGES
) => {
	type NavPage = {
		enabled: boolean;
		pageNumber: number | null;
	};

	interface IResultProps {
		previous: NavPage;
		pages: number[];
		next: NavPage;
	}

	const result: IResultProps = {
		previous: {
			enabled: canPreviousPage,
			pageNumber: pageIndex > 1 ? pageIndex - 1 : null
		},
		pages: [],
		next: {
			enabled: canNextPage,
			pageNumber: pageIndex < pageCount ? pageIndex + 1 : null
		}
	};

	if (pageCount <= maxPages) {
		result.pages = getPageRange(1, pageCount);
	} else {
		const halfOfMaxPages = Math.floor(maxPages / 2); 

		if (pageIndex === pageCount) {
			// We want the current page to be the last page, and fill in left from there
			result.pages = getPageRange(pageCount - (maxPages - 1), maxPages);
		} else if (pageIndex <= halfOfMaxPages) {
			// We want the current page to be left of the halfway mark of max pages, filling in the rest
			const diffBetweenCurrentAndHalf = halfOfMaxPages - pageIndex;
			const pageCountLeftOfCurrent = halfOfMaxPages - (diffBetweenCurrentAndHalf + 1);

			result.pages = getPageRange(pageIndex - pageCountLeftOfCurrent, maxPages);
		} else if (pageIndex > pageCount - halfOfMaxPages) {
			// We want the current page to be to the right of the halfway mark of max pages, filling in the rest
			const diffBetweenTotalAndCurrent = pageCount - pageIndex;
			const pageCountRightOfCurrent = maxPages - diffBetweenTotalAndCurrent;

			result.pages = getPageRange(pageCount - pageCountRightOfCurrent, maxPages);
		} else {
			// We want the current page as "centered" as possible, filling in the rest
			result.pages = getPageRange(pageIndex - halfOfMaxPages, maxPages);
		}
	}

	return result;
};
