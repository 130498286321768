import React, { FC, HTMLAttributes, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { Tooltip } from '../Tooltip';
import {
  TTooltipArrow,
  TTooltipTheme,
  TTextAlignment,
} from '../../common/types';
import classNames from 'classnames';

const position = {
  'bottom-center': 'right-[50%] bottom-6 translate-x-1/2',
  'bottom-left': 'left-0 bottom-6 translate-x-[-12px]',
  'bottom-right': 'right-0 bottom-6 translate-x-[12px]',
  'top-center': 'right-[50%] top-6 translate-x-1/2',
  left: 'bottom-[50%] left-6 translate-y-1/2',
  right: 'bottom-[50%] right-6 translate-y-1/2',
  none: 'right-[50%] bottom-5 translate-x-1/2',
};
export interface Props extends HTMLAttributes<HTMLDivElement> {
  tooltipTheme: TTooltipTheme;
  tooltipArrow: TTooltipArrow;
  tooltipText: string;
  tooltipSupportText?: string;
  className?: string;
  tooltipTextAlign?: TTextAlignment;
}

export const HelpIcon: FC<Props> = ({
  className = '',
  tooltipArrow,
  tooltipTheme,
  tooltipText,
  tooltipSupportText,
  tooltipTextAlign = 'text-left',
}) => {
  const [hideTooltip, setHideTooltip] = useState<boolean>(true)
  return (
    <div
      className={`relative w-max text-gray-400 group cursor-pointer ${className}`}
    >
      <div onMouseOver={() => setHideTooltip(false)} onMouseOut={() => setHideTooltip(true)}>
        <FeatherIcon icon="help-circle" size={16} />
      </div>
      <Tooltip
        arrow={tooltipArrow}
        theme={tooltipTheme}
        text={tooltipText}
        supportText={tooltipSupportText}
        className={classNames(`${position[tooltipArrow]} ${tooltipTextAlign}`,{'hidden': hideTooltip})}
      />
    </div>
  )
}