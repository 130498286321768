import React from 'react'
import type { IBalance } from '../common/types'
import { formatNumber } from '../common/utils'
import { useTranslation } from "react-i18next"

interface Props {
  balances: IBalance[] | undefined
}

const Portfolio = ({ balances }: Props) => {
  const totalBalance = balances?.reduce((previousBalance, currentBalance) => previousBalance + currentBalance.price! * currentBalance.balance, 0)
  const parts = (1234.5).toLocaleString(process.env.REACT_APP_DEFAULT_LOCALE_STRING || 'es-AR').match(/(\D+)/g)!
  const totalBalanceFormatted = formatNumber(totalBalance!, false)
  const [integerPart, decimalPart] = totalBalanceFormatted.split(parts[1])
  const fullDecimalPart: string = parts[1].concat(decimalPart ? decimalPart : '00')
  const { t } = useTranslation()

  return (
    <div className="flex flex-col font-normal mb-10">
      <p className="text-gray-700 text-sm">{t("portfolio.title")}</p>
      <div className="flex flex-row text-gray-900 tracking-tight" style={{ fontSize: '36px', lineHeight: '44px' }}>
        <span className="mr-3">{process.env.REACT_APP_LOCAL_CURRENCY}</span>
        <span>{integerPart}</span>
        <span className=" text-gray-400">{fullDecimalPart}</span>
      </div>
      <p className="text-gray-700 text-sm mt-1">{t("portfolio.description")}{process.env.REACT_APP_LOCAL_CURRENCY}</p>
    </div>
  )
}

export default Portfolio
