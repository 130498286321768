import React, { FC, HTMLAttributes, ReactChild } from 'react'
import FeatherIcon from 'feather-icons-react'
import spinner from '../../assets/icons/spinner.svg'
import { Link } from 'react-router-dom'

export interface Props extends HTMLAttributes<HTMLDivElement> {
  label?: ReactChild
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
  variant?: string
  disabled?: boolean
  iconPosition?: 'leading' | 'trailing' | 'dot' | 'only'
  icon?: string
  type?: 'button' | 'submit'
  className?: string
  onClick?: () => void
  href?: string
  //! Definir mejor el type
  to?: string | { pathname: string; state: { [key: string]: any } }
  isLoading?: boolean
}

const variants: { [key: string]: string } = {
  primary:
    'bg-primary-600 text-white border border-primary-600 shadow-xs hover:bg-primary-700 hover:border-primary-700 focus:shadow-focus-primary disabled:bg-primary-200 disabled:border-primary-200',
  secondary:
    'bg-primary-50 text-primary-700 border-primary-50 shadow-xs hover:bg-primary-100 hover:border-primary-100 focus:shadow-focus-primary disabled:bg-primary-25 disabled:border-primary-25 disabled:text-primary-300',
  'secondary-gray':
    'bg-white text-gray-700 border border border-gray-300 shadow-xs hover:bg-gray-50 hover:border-gray-300 focus:shadow-focus-secondary-gray disabled:bg-white disabled:border-gray-200 disabled:text-gray-300',
  tertiary: 'bg-white text-primary-700 hover:bg-primary-50 disabled:text-gray-300 disabled:bg-white',
  'tertiary-gray': 'bg-white text-gray-500 hover:bg-gray-50 disabled:text-gray-300 disabled:bg-white',
  link: 'text-primary-700 hover:text-primary-800 disabled:text-gray-300',
  'link-gray': 'text-gray-500 hover:text-gray-600 disabled:text-gray-300',
  modal: 'rounded-full bg-gray-100 border border-gray-50 border-8 text-gray-600'
}

const fontSizes: { [key: string]: string } = {
  sm: 'text-sm',
  md: 'text-sm',
  lg: 'text-md',
  xl: 'text-md',
  '2xl': 'text-lg'
}

const paddings: { [key: string]: string } = {
  sm: 'px-3.5 py-2',
  md: 'px-4 py-2.5',
  lg: 'px-4 py-2.5',
  xl: 'px-5 py-3',
  '2xl': 'px-7 py-4'
}

const paddingsOnly: { [key: string]: string } = {
  sm: 'p-2',
  md: 'p-2.5',
  lg: 'p-3',
  xl: 'p-3.5',
  '2xl': 'p-4'
}

export const Button: FC<Props> = ({
  label,
  variant = '',
  size = 'md',
  disabled,
  icon = '',
  iconPosition = 'leading',
  type = 'button',
  className = '',
  onClick,
  href = '',
  to,
  isLoading
}) => {
  return href ? (
    <a
      href={href}
      className={`${className} ${variants[variant]} ${fontSizes[size]} rounded inline-block ${
        !['link', 'link-gray'].includes(variant) && iconPosition !== 'only' ? paddings[size] : ''
      } ${!['link', 'link-gray'].includes(variant) && iconPosition === 'only' ? paddingsOnly[size] : ''}`}
    >
      <span className={`flex items-center justify-center ${icon && iconPosition === 'trailing' && 'flex-row-reverse'}`}>
        {icon !== null && iconPosition !== 'dot' && (
          <FeatherIcon
            icon={icon}
            className={`${size === '2xl' ? 'w-6 h-6' : 'w-5 h-5'} ${iconPosition === 'leading' ? 'mr-3' : iconPosition === 'trailing' ? 'ml-3' : ''}`}
          />
        )}
        {iconPosition !== 'only' && label}
      </span>
    </a>
  ) : to ? (
    <Link
      to={to}
      className={`${className} ${variants[variant]} ${fontSizes[size]} rounded inline-block ${
        !['link', 'link-gray'].includes(variant) && iconPosition !== 'only' ? paddings[size] : ''
      } ${!['link', 'link-gray'].includes(variant) && iconPosition === 'only' ? paddingsOnly[size] : ''}`}
    >
      <span className={`flex items-center justify-center ${icon && iconPosition === 'trailing' && 'flex-row-reverse'}`}>
        {icon !== null && iconPosition !== 'dot' && (
          <FeatherIcon
            icon={icon}
            className={`${size === '2xl' ? 'w-6 h-6' : 'w-5 h-5'} ${iconPosition === 'leading' ? 'mr-3' : iconPosition === 'trailing' ? 'ml-3' : ''}`}
          />
        )}
        {iconPosition !== 'only' && label}
      </span>
    </Link>
  ) : (
    <button
      className={`${className} ${variants[variant]} ${fontSizes[size]} rounded flex items-center justify-center ${
        icon && iconPosition === 'trailing' && 'flex-row-reverse'
      } ${!['link', 'link-gray'].includes(variant) && iconPosition !== 'only' ? paddings[size] : ''} 
      ${!['link', 'link-gray'].includes(variant) && iconPosition === 'only' ? paddingsOnly[size] : ''}`}
      onClick={onClick}
      disabled={disabled || isLoading}
      type={type}
    >
      {icon !== '' && iconPosition !== 'dot' && (
        <FeatherIcon
          icon={icon}
          className={`${size === '2xl' ? 'w-6 h-6' : 'w-5 h-5'} ${iconPosition === 'leading' ? 'mr-3' : iconPosition === 'trailing' ? 'ml-3' : ''}`}
        />
      )}
      {iconPosition !== 'only' && label}
      {isLoading && <img className="ml-2 inline w-5" src={spinner} alt="spinner" />}
    </button>
  )
}
