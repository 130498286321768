import React, { useState } from 'react'
import Logo from '../Logo'
import { ILink } from '../../common/types'
import styles from './Navbar.module.css'
import hamburger from '../../assets/icons/hamburger.svg'
import close from '../../assets/icons/close.svg'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
interface Props {
  links: ILink[]
}

const Navbar = ({ links }: Props) => {

  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  return (
    <nav className={` ${open ? 'h-screen' : `${styles.topBar}`} z-50 p-3 transition-all duration-400 ease-in-out fixed overflow-hidden lg:overflow-visible lg:static top-0 w-full bg-white lg:px-4 flex lg:items-center lg:justify-between border-b border-gray-lighter flex-col lg:flex-row`}>
      <div className="flex flex-col lg:flex-row">
        <div className="mr-10 mb-10 lg:mb-0">
          <Logo logo="logo_standard.svg" link={process.env.REACT_APP_AGROTOKEN_OARO_APP_BASE_URL + '/app/overview'} />
          <button type="button" onClick={() => setOpen(!open)} className="border-none bg-white lg:hidden absolute top-6 right-5">
            {open ? <img src={close} alt="menu" /> : <img src={hamburger} alt="menu" />}
          </button>
        </div>
        <ul className="flex flex-col space-y-8 lg:flex-row lg:space-x-6 lg:space-y-0 mt-0.5">
          {links.map((item) => (
            <li key={item.id}>
              <a
                href={!item.active ? process.env.REACT_APP_AGROTOKEN_OARO_APP_BASE_URL + item.url : item.url}
                onClick={() => setOpen(false)}
                className={classNames(`lg:border-b-2 border-white lg:hover:border-primary lg:pb-5 ${styles.navLink}`, { 'border-primary text-primary hover:text-primary': item.active, 'text-gray-500 hover:text-gray-700': !item.active })}>{t("header.links." + item.translationKey)}</a>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-2 ml-0.5 lg:mt-0 flex lg:items-center flex-col-reverse space-y-reverse space-y-4 lg:space-y-0 lg:space-x-4 lg:flex-row pt-3.5 pb-5">
        <div className="space-y-8 mt-1.5">
          <a className={`mx-0 lg:border-b-2 border-white lg:hover:border-primary text-gray-500 hover:text-gray-700 lg:pb-5 ${styles.navLink}`} href={process.env.REACT_APP_AGROTOKEN_OARO_APP_BASE_URL + '/app/account'}>
            {t("header.links.accountProfile")}
          </a>
        </div>
      </div>
    </nav>
  )
}

export default Navbar