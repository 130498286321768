import React from 'react'

interface Props {
  logo: string
  link?: string
}

const Logo = ({ logo, link }: Props) => {
  const srcLogo = require(`../assets/logos/${logo}`).default

  const RenderLogo = () => {
    if (link)
      return (
        <a href={link} className="relative">
          <img className="pl-2 h-7" src={srcLogo} alt="Logo" />
        </a>
      )
    return <img className="pl-2 h-7 relative top-1" src={srcLogo} alt="Logo" />
  }

  return (
    <div className="flex items-center ">
      <RenderLogo />
    </div>
  )
}

export default Logo
